<template>
<div>
  <v-data-table
    :headers="headers"
    :items="trainDataItems"
    :sortDesc="true"
    item-key="_id"
    class="elevation-1"
    height="600"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:no-data>
      데이터가 없습니다.
    </template>
    <template v-slot:top>
      <v-toolbar color="white">
          <v-col md="4">
              <v-text-field
                v-model="childDesSearch"
                label="발화 조회"
                outlined
                dense
                class="pt-7"
                @keyup.enter="textSearch"
                ></v-text-field>
          </v-col>
          <v-col md="1">
            <v-btn color="#D9CDBF" @click="textSearch()">조회</v-btn>
          </v-col>
<!--          <v-col md="1"></v-col>-->
          <v-col md="4">
              <v-text-field
                v-model="desAdd"
                label="발화 등록"
                outlined
                dense
                class="pt-7"
                @keyup.enter="textCreate"
                ></v-text-field>
            </v-col>

            <v-col md="1">
                <v-btn color="#D9CDBF" @click="textCreate()">발화 등록</v-btn>
            </v-col>
          <v-col md="1">
              <v-btn color="#D9CDBF" @click="eachDownload(typeName)">다운로드</v-btn>
          </v-col>
            <v-col md="1">
                <v-btn color="#D9CDBF" @click="eachDelete(typeName)">{{ typedict[typeName] }} 삭제</v-btn>
            </v-col>

      </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{item}">
      <v-icon @click="editDescripion(item)" color="teal">edit</v-icon>
      <v-icon @click="delDescription(item)" color="teal">delete</v-icon>
    </template>
    <template v-slot:footer>
      <div class="pagination_wrapper">
        <span class="search_count">
          검색결과 : <em>{{totalCount}}</em>개
        </span>
        <v-pagination v-model="page" :length="pageCount" :total-visible="limitPageVisible" @input="handlePageChange" />
<!--        <v-pagination v-model="page" :length="pageCount" :total-visible="limitPageVisible" />-->
      </div>
    </template>
  </v-data-table>

</div>
</template>

<script>
  export default {
    name : "TrainDataListComponent",
    props: {
      total: Number,
      desSearch: String,
      typeName: String,
      // pagination: {},

      trainDataItems: Array,
      intentId: String,
      pageCount: Number,
    },
    data () {
      return {
        limitPageVisible : 10,
        desAdd : '',
        // desSearch: '',
        page: 1,
        headers: [
          { align:'center',text: '수정/삭제', value: 'action', width: "10%", sortable: false },
          { text: '발화', value: 'text', width: "70%" },
          { text: 'Intent', value: 'intent', width: "20%" },
        ],
        desserts: [],
        pagination: {
        },
        footerProps : {
          // 'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-options': [10, 15, 30 , 50, 100],
          'items-per-page-text': 'Showing items:',
          'items-per-page-all-text': 'All of it',
        },
        typedict: {
          'train': '학습셋',
          'valid': '개발셋',
          'test': '평가셋',
        },
        isSearched : false,
      }
    },
    watch: {
      pagination (value) {
        // console.log(this.page)
        // console.log(this.pagination.page)
          // this.$set(this.pagination, 'page', 1)
        // this.$emit('paging', value.page , value.itemsPerPage)
        value.change = false
        this.$emit('paging', value)
      },
      intentId : {
        handler(n,o){
          if( n !== o ){
            // 해당 조회하는 인텐트가 변경이 되었을때, 초기 커런트 페이지를 1로 되돌린다.
            this.page = 1;
          }
        },
        deep : true
      }
    },
    methods: {
      textCreate() {
        if (this.desAdd != "") {
          this.$emit('textCreate', this.desAdd)
            this.desAdd = ''
        }
      },
      textSearch() {
        this.isSearched = true;
        this.$emit('textSearch', this.childDesSearch)
      },
      delDescription(item) {
        this.$emit('delDescription', item)
      },
      editDescripion(item) {
        this.$emit('editDescripion', item)
      },
      eachDelete(typeName) {
        this.$emit('eachDelete', typeName)
      },
      eachDownload(typeName) {
        this.$emit("eachDownload", typeName)
      },
      handlePageChange(value) {
        this.$emit('paging', {page: value, itemsPerPage: 100})
      },
    },
    computed : {
      totalCount () {
        return this.total
      },
      childDesSearch: {
        get() {
          return this.desSearch
        },
        set(newVal) {
          this.$emit('updateDesSearch', newVal)
        }
      },
    },
  }
</script>
