
<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    max-width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">
        <span>Confusion Matrix</span>
      </v-card-title>
      <v-card-text class="text-xs-left subheading">
        <v-container> 
          <v-flex>
          </v-flex>
          <v-flex>
            <vue-good-list
              :row-style-class="rowStyleClassFn"
              :columns="headers"
              
              :sort-options="{
                  enabled: false,
                }"                     
              :rows="dataSource">
            </vue-good-list>
          </v-flex>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="close"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>   
  </v-dialog>
</template>
<script>


import  VueGoodList from '@/components/grid/VueGoodList.vue'

const random = () => {
  return parseInt(Date.now() + Math.random() * 10000000).toString(16).slice(-6)
}
export default {
  components: {
    VueGoodList,

  }, 
  data () {
    return {
      cnt: 0, 

      series: [], 

    }
  },  
  created () {
    console.log('created')

  },  
  mounted () {
    // for (let i = 0; i < 12; i++) {
    //   this.params.data.push([i+1, `${random()}`, `${random()}`, `${random()}`])
    // }
    console.log('mounted')
    console.log(this.headers)
    // console.log(this.headers)
    // this.headers.forEach(d => {
    //     d['tdClass'] = this.tdClassFunc
    //   })    
  },
  props: {
    dataSource: Array,
    headers: Array, 
    // xdataSource: Array, 
    xLabels: Array, 
    icon: String,
    message: String,
    dialog: Boolean
  },


  methods: {
    customRowClass (item) {
      console.log(item)
      return 'custom-bg'
    } ,   
    close () {
      // console.log(this.yALabels)
      this.$emit('close', false)
    },
    rowStyleClassFn(row) {
      // return row.value > 18 ? 'green' : 'red';
    },    
    // tdClassFunc(row) {
    //   console.log('row :', row['2-1-3 예약송금'])
    //   if (row['2-1-3 예약송금'] > 50) {
    //     return 'red-class';
    //   }
    //   return 'true-class';
    // },    
  }
}
</script>
<style>
  .red-class {
    color: 'red'
  }
  .test-class {
    background-color: #F3F3F3
  }
  .level1-class {

  }
  .level2-class {

  }
  .level3-class {

  }

  /* .true-class {
    background-color: 'green'
  }   */
</style>