<template>
  <v-layout column ma-5>
    <v-layout column>
      <v-toolbar dark color="#59493B" class="mb-1">
        봇 리스트
      </v-toolbar>
      <bot-list
        :botItems="botItems"
        :taskItems="taskItems"
        :childBotItems="childBotItems"
        :total="total"
        @paging="paging"
        @serviceConfirm="serviceConfirm"
        @serviceDelete="serviceDelete"
        @getModelList="getModelList"
        @modelDelete="modelDelete"
        @deployValidCheck="deployValidCheck"
      ></bot-list>
      <alert-dialog
        :dialog="alertDialog['isShow']"
        :title="alertDialog['title']"
        :message="alertDialog['message']"
        :icon="alertDialog['icon']"
        @close="alertDialog['isShow'] = false"
      ></alert-dialog>
      <v-dialog v-model="updateDialog['isShow']" max-width="580">
        <v-card>
          <v-card-title class="headline">
            <v-icon color="primary" class="mr-2" medium>{{
              updateDialog["icon"]
            }}</v-icon>
            <span>API 테스트</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">
            <v-container>
              <v-layout column>
                <v-layout row>
                  <v-flex xs10>
                    <v-text-field
                      :label="'utterance'"
                      v-model="utterance_text"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn color="primary" @click="updateConfirm">
                      테스트
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <tree-view
                    :data="'result_text'"
                    :options="sampleOptions"
                  ></tree-view>
                  <v-textarea
                    background-color="grey lighten-2"
                    color="cyan"
                    label="결과"
                    height="150"
                    readonly
                    v-model="result_text"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="updateDialog['isShow'] = false">
              닫기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-layout>
</template>

<script>
import {
  getDatetime,
  decodeToken,
  getCookie,
  deleteCookie,
  globalCatch,
} from "@/utils";
import AlertDialog from "@/components/common/AlertDialog.vue";
import BotList from "@/components/BotLog/BotList.vue";

export default {
  components: {
    //   CommonDialog
    AlertDialog,
    BotList,
  },
  data() {
    return {
      commonDialog: {
        isShow: false,
        title: "",
        message: "",
      },
      botItems: [],
      taskItems: [],
      time_flag: 0,
      childBotItems: [],
      total: 0,
      utterance_text: "",
      result_text: "",
      updateDialog: {
        isShow: false,
        name: null,
        type: null,
        icon: null,
        ttl: null,
        description: null,
        botid: null,
      },
      alertDialog: {
        isShow: false,
        title: "알림",
        message: "",
        icon: "",
      },
      sampleOptions: {
        // maxDepth: 10,
        // rootObjectKey: 'root',
        modifiable: false,
        link: false,
      },
    };
  },
  mounted() {
    console.log("mounted");
    console.log(this.$store.state.timerId);
    clearInterval(this.$store.state.timerId);
    // clearInterval(this.timeid)
  },
  created() {
    console.log("created");

    this.search();
  },
  computed: {},
  methods: {
    paging(value) {},
    search() {
      const customer = this.$store.state.user.customer;
      this.$http
        .get(process.env.VUE_APP_BOT_DATA_LIST, {
          params: {
            customer: customer,
            type: "parent",
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.botItems = data;
          this.total = res.data.total;
          console.log(this.botItems);
          this.botItems.forEach((d) => {
            d["last_deployed"] = getDatetime(d["last_deployed"]);
          });
        })
        .catch((error) => {
          if (error.status == 403) {
            globalCatch(error);
            this.$router.push({ name: "login" });
          }
        });
    },

    serviceConfirm(item) {
      const botid = item["botid"];
      this.$http
        .post(process.env.VUE_APP_BOT_SERVICE_CONFIRM, {
          botid: botid,
        })
        .then((res) => {
          const data = res.data.data;

          this.alertDialog["isShow"] = true;
          this.alertDialog["message"] = "서비스 반영되었습니다.";
          // alert('서비스 반영되었습니다.')
          this.search();
        });
    },
    serviceDelete(item) {
      const botid = item["botid"];
      this.$http
        .post(process.env.VUE_APP_BOT_SERVICE_DELETE, {
          botid: botid,
        })
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          // alert('서비스 삭제되었습니다.')
          this.alertDialog["isShow"] = true;
          this.alertDialog["message"] = "서비스 삭제되었습니다.";
          this.search();
        })
        .catch((error) => {
          if (error.status == 403) {
            globalCatch(error);
            this.$router.push({ name: "login" });
          }
        });
    },
    getModelList(value) {
      //  expaned
      const botid = value.item.botid;
      // console.log('botid :', botid)
      this.$http
        .get(process.env.VUE_APP_BOT_TASKS_LIST, {
          params: {
            parent_botid: botid,
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.taskItems = data;
          this.taskItems.forEach((d) => {
            d["accuracy"] = d["accuracy"].toFixed(2);
            if (d["bot_type"] == "parent") {
              d["bot_type"] = "ebot";
            } else {
              d["bot_type"] = "pbot";
            }
          });
        })
        .catch((error) => {
          if (error.status == 403) {
            globalCatch(error);
            this.$router.push({ name: "login" });
          }
        });

      this.childBotItems = [];
      this.$http
        .get(process.env.VUE_APP_BOT_DATA_LIST, {
          params: {
            customer: value.item.customer,
            parent_botid: value.item.botid,
            type: "child",
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.childBotItems = data;
          this.childBotItems.forEach((d) => {
            d["last_deployed"] = getDatetime(d["last_deployed"]);
          });
        })
        .catch((error) => {
          if (error.status == 403) {
            globalCatch(error);
            this.$router.push({ name: "login" });
          }
        });
    },
    modelDelete(item) {
      this.$store.dispatch("MODAL_ACTION", {
        isShow: true,
        callback: () => {
          this.$http
            .post(process.env.VUE_APP_MODEL_DEPLOY_DELETE, {
              req_id: item.req_id,
              botid: item.botid,
            })
            .then((res) => {
              //alert('배포 삭제 되었습니다.')
              this.alertDialog["isShow"] = true;
              this.alertDialog["message"] = "배포 삭제 되었습니다.";
              this.search();
            })
            .catch((error) => {
              if (error.status == 403) {
                globalCatch(error);
                this.$router.push({ name: "login" });
              }
            });
        },
        text: "해당 버전의 배포를 삭제 하시겠습니까?",
      });
    },
    deployValidCheck(item, type) {
      // console.log(item, type)
      this.result_text = "";
      this.utterance_text = "";
      this.updateDialog["isShow"] = true;
      this.updateDialog["botid"] = item.botid;
    },
    updateConfirm() {
      const botid = this.updateDialog["botid"];
      if (this.utterance_text == "") {
        // alert('검증할 검증 키워드를 입력해 주세요.')
        this.alertDialog["isShow"] = true;
        this.alertDialog["message"] = "검증할 검증 키워드를 입력해 주세요.";
        return;
      }
      this.$http
        .post(process.env.VUE_APP_VALID_CHECK, {
          botid: botid,
          utterance: this.utterance_text,
        })
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          // console.log(res.data.template)
          if (res.data.err_msg !== undefined) {
            this.alertDialog["isShow"] = true;
            this.alertDialog["message"] = res.data.err_msg;
          }
          this.result_text = res.data.template;
        })
        .catch((error) => {
          if (error.status == 403) {
            globalCatch(error);
            this.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>
