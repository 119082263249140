<template>
  <v-layout>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items="botItems"
        :options.sync="pagination"
        :items-per-page="10"
        :server-items-length="total"
        :expand="expand"            
        item-key="_id"
        class="elevation-1"
        single-expand
        show-expand
        @item-expanded="clicked"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.valid_check`]="{item}">
          <v-btn
            class="bot-list-btn"
            outlined
            color="teal darken-3"
            @click="deployValidCheck(item, 'parent')"
          >
            <v-icon left color="teal darken-3" >mdi-update</v-icon>
            <span>API 테스트</span>
          </v-btn>
        </template>
        <template v-slot:[`item.deploy_delete`]="{item}">
          <v-btn
            class="bot-list-btn"
            outlined
            color="deep-orange darken-1"
            @click="deployDelete(item, 'parent')" 
            v-if="item.deploy == true"
          >          
            <v-icon left color="deep-orange darken-1">mdi-cancel</v-icon>
            <span>배포 삭제</span>
          </v-btn>
        </template>            
        <template v-slot:[`item.confirm`]="{item}">
          <v-btn
            class="bot-list-btn"
            outlined
            color="light-blue darken-4"
            @click="serviceConfirm(item)"
            v-if="item.deploy == true && !item.service_confirm"
          >          
            <v-icon left color="light-blue darken-4">mdi-cloud-upload</v-icon>
            <span>서비스 반영</span>
          </v-btn>
        </template>      
        <template v-slot:[`item.delete`]="{item}">
          <v-btn
            class="bot-list-btn"
            outlined
            color="grey darken-2"
            v-if="item.deploy == true && item.service_confirm"
            @click="serviceDelete(item)"
          >          
            <v-icon left color="grey darken-2" >mdi-cancel</v-icon>
            <span>서비스 삭제</span>
          </v-btn>
        </template>    
        <template v-slot:expanded-item="{ headers }">        
          <td :colspan="headers.length">
            <v-flex pa-4 grey lighten-4>
              <v-card>
                <v-data-table
                  :headers="childBotHeaders"
                  :items="childBotItems"
                  item-key="_id"
                  class="elevation-1"
                  :footer-props="footerProps"
                >
                  <template v-slot:top>
                    <v-toolbar 
                      dark
                      color="#59493B"
                      class="mb-1"
                    >
                    서비스봇 리스트
                    </v-toolbar>
                  </template> 
                  <template v-slot:[`item.valid_check`]="{item}">
                    <v-btn
                      class="bot-list-btn"
                      outlined
                      color="teal darken-3"
                      @click="deployValidCheck(item, 'parent')"
                    >
                      <v-icon left color="teal darken-3" >mdi-update</v-icon>
                      <span>API 테스트</span>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.deploy_delete`]="{item}">
                    <v-btn
                      class="bot-list-btn"
                      outlined
                      color="deep-orange darken-1"
                      @click="deployDelete(item, 'parent')" 
                      v-if="item.deploy == true"
                    >          
                      <v-icon left color="deep-orange darken-1">mdi-cancel</v-icon>
                      <span>배포 삭제</span>
                    </v-btn>
                  </template>            
                  <template v-slot:[`item.confirm`]="{item}">
                    <v-btn
                      class="bot-list-btn"
                      outlined
                      color="light-blue darken-4"
                      @click="serviceConfirm(item)"
                      v-if="item.deploy == true && !item.service_confirm"
                    >          
                      <v-icon left color="light-blue darken-4">mdi-cloud-upload</v-icon>
                      <span>서비스 반영</span>
                    </v-btn>
                  </template>      
                  <template v-slot:[`item.delete`]="{item}">
                    <v-btn
                      class="bot-list-btn"
                      outlined
                      color="grey darken-2"
                      v-if="item.deploy == true && item.service_confirm"
                      @click="serviceDelete(item)"
                    >          
                      <v-icon left color="grey darken-2" >mdi-cancel</v-icon>
                      <span>서비스 삭제</span>
                    </v-btn>
                  </template>                      
                </v-data-table>      
              </v-card>
            </v-flex>
          </td>
        </template>
        <template v-slot:footer>
          <div class="pagination_wrapper">
            <v-pagination v-model="page" :length="pageCount" :total-visible="limitPageVisible" />
          </div>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>

</template>
<script>
  export default {
    data () {
      return {
        // selectIntent: '', 
        correctSelected: null, 
        correctItems:[
          {'value':  null, 'display': '전체'}, 
          {'value': true, 'display': 'true'}, 
          {'value': false, 'display': 'false'}  
        ], 
        page: 1, 
        limitPageVisible: 10,
        headers: [
          { text: 'botid', value: 'parent_botid', width: "20%" },
          { text: 'botname', value: 'botname', width: "20%" },
          { text: '배포 일자', value: 'last_deployed', width: "10%" },
          { text: '모델 버전', value: 'model_version', width: "10%"},
          // { text: '배포 정확도', value: 'accuracy', width: "100" },
          { align: 'center',text: 'API 테스트', value: 'valid_check', width: "10%", sortable: false },
          { align: 'center',text: '배포 삭제', value: 'deploy_delete', width: "10%", sortable: false },
          { align: 'center',text: '서비스 반영', value: 'confirm', width: "10%", sortable: false },
          { align: 'center',text: '서비스 삭제', value: 'delete', width: "10%", sortable: false },
        ],
        childBotHeaders:[
          { text: '서비스봇', value: 'botid', width: "20%" },
          { text: 'botname', value: 'botname', width: "20%" },
          { text: '배포 일자', value: 'last_deployed', width: "10%" },
          { text: '모델 버전', value: 'model_version', width: "10%" },
          // { text: '배포 정확도', value: 'accuracy', width: "100" },          
          { align: 'center',text: 'API 테스트', value: 'valid_check', width: "10%", sortable: false },
          { align: 'center',text: '배포 삭제', value: 'deploy_delete', width: "10%", sortable: false },
          { align: 'center',text: '서비스 반영', value: 'confirm', width: "10%", sortable: false },
          { align: 'center',text: '서비스 삭제', value: 'delete', width: "10%", sortable: false },
        ], 
        taskHeaders: [
          { align: 'center',text: 'botid', value: 'botid', width: "150", sortable: false},
          { align: 'center',text: '봇타입', value: 'bot_type', width: "150", sortable: false},
          { align: 'center',text: '모델 버전', value: 'version', width: "150", sortable: false},
          { align: 'center',text: '정확도', value: 'accuracy', width: "150", sortable: false},
          { align: 'center',text: '학습시작', value: 'start_time', width: "150", sortable: false},
          { align: 'center',text: '학습종료', value: 'end_time', width: "150", sortable: false},
          { align: 'center',text: '완료예정시간', value: 'estimated_end_time', width: "150", sortable: false},
          { align: 'center',text: '상태', value: 'status', width: "150", sortable: false},
          // { align: 'center',text: '배포 삭제', value: 'deploy_delete', width: "40", sortable: false},
        ], 
        desserts: [],
        pagination: {
        },
        footerProps : { 
          'items-per-page-options': [10, 15, 30 , 50, 100],
          'items-per-page-text': 'Showing items:',
          'items-per-page-all-text': 'All of it'
        },
        expand: false, 
      }
    },
    props: {
      total: Number,
      botItems: Array, 
      taskItems: Array, 
      childBotItems: Array, 
    }, 
    watch: {
      pagination (value) {
        value.change = false
        this.$emit('paging', value)
      }
    }, 
    methods: {
      serviceConfirm (item) {
        this.$emit('serviceConfirm', item)
      },
      serviceDelete (item) {
        this.$emit('serviceDelete', item)
      },
      clicked (value) {
        this.$emit('getModelList', value)
      }, 
      modelDelete (item) {
        this.$emit('modelDelete', item) 
      }, 
      deployDelete (item, type) {
        if (item.service_confirm == true) {
          alert('서비스 반영이 되어 배포를 삭제할수 없습니다.') 
        } else {
          this.$emit('modelDelete', item, type)
        }
      },
      deployValidCheck(item, type) {
        this.$emit('deployValidCheck', item, type)
      }
    },
    computed : {
      pages () {
        if (this.rowsPerPage == null || this.trainDataItems == null) {
          return 0
        } else {
          return Math.ceil(this.total / this.rowsPerPage)
        }
      },
      totalCount () {
        return this.total
      }
    },
  }
</script>