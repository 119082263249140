<template>
<div>
  <v-data-table
      :headers="headers"
      :items="snapshotItems"
      style="padding-left:480px;"
      height="580"
      class="elevation-1"
      item-key="_id"
      disable-pagination
      :hide-default-footer="true"
      show-select
      v-model="selected"
  >
    <template v-slot:no-data>
      데이터가 없습니다.
    </template>
    <template v-slot:top>
      <v-toolbar color="white">
        <v-col md="2">
          <v-text-field
              v-model="desSearch"
              label="발화 조회"
              outlined
              dense
              class="pt-7"
              @keyup.enter="textSearch"
          ></v-text-field>
        </v-col>
        <v-col md="1.5">
          <v-select
              v-model="correctSelected"
              outlined
              item-text="display"
              item-value="value"
              :items="correctItems"
              dense
              value=null
              class="pt-7"
              label="Correct"
          ></v-select>
        </v-col>
        <v-col md="1.5">
          <v-select
              v-model="firstIntentSortSelected"
              outlined
              item-text="display"
              item-value="value"
              :items="sortItems"
              dense
              value=null
              class="pt-7"
              label="1위 Intent"
          ></v-select>
        </v-col>
        <v-col md="1">
          <v-btn color="#D9CDBF" @click="textSearch()">조회</v-btn>
        </v-col>
        <v-col md="1.5">
          <v-btn color="#D9CDBF" @click="moveToTrain()">학습셋 이동</v-btn>
        </v-col>
        <v-col md="1"></v-col>
        <v-col md="1.5">
          <v-select
              outlined dense class="pt-7"
              v-model="pageSize"
              :items="pageSizes"
              label="Page Size"
              @change="handlePageSizeChange" />
        </v-col>
        <v-col md="2">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="#D9CDBF" v-on="on">설정/다운로드</v-btn>
            </template>
            <v-list>
              <v-list-item dense v-for="item in otherFuncItems" :key="item.value" @click="handleOtherFuncChange(item)">
                <v-list-item-title>{{ item.display }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-toolbar>
    </template>

    <template v-slot:footer>
      <div class="pagination_wrapper">
        <span class="search_count">
          검색결과 : <em>{{totalCount}}</em>개
        </span>
        <v-pagination v-model="page" :length="pageCount" :total-visible="limitPageVisible" @input="handlePageChange" />
      </div>
    </template>
  </v-data-table>
</div>
</template>

<script>
  export default {
    data () {
      return {
        // selectIntent: '',
        desAdd : '',
        // desSearch: '',
        // correctSelected: null,
        isSearched : false,
        limitPageVisible: 10,
        correctItems:[
          {'value':  0, 'display': '전체'},
          {'value': 1, 'display': 'true'},
          {'value': 2, 'display': 'false'}
        ],
        confidenceItems:[],
        page: 1,
        headers: [
          { text: '발화', value: 'text', width: "300"  , sortable: true},
          { text: 'correct', value: 'correct', width: "100" ,sortable: true},
          { text: '1위 intent', value: 'first_data', width: "250", sortable: false  },
          { text: '2위 intent', value: 'two_data', width: "250", sortable: false },
          { text: '3위 intent', value: 'three_data', width: "250", sortable: false },
          { text: '검증',       value: 'intent_aiu_api', width: "100", sortable: false},

        ],
        expandHeaders: [
          { text: '순위', value: 'idx', width: "100" },
          { text: 'intent', value: 'labeled', width: "100" },
          { text: 'confidence', value: 'confidence_score', width: "200" },
        ],
        desserts: [],
        pagination: {
        },
        footerProps : {
          'items-per-page-options': [10, 15, 30 , 50, 100],
          'items-per-page-text': 'Showing items:',
          'items-per-page-all-text': 'All of it'
        },
        pageSize: 10,
        pageSizes: [10, 15, 30 , 50, 100],
        otherFuncItems: [
          {'value':'train_down', 'display':'학습 다운로드'},
          {'value':'test_down', 'display':'평가 다운로드(검증 필드 제외)'},
          {'value':'test_down_with_verification', 'display':'평가 다운로드(검증 필드 포함)'},
          {'value':'show_test_download_setting_dialog', 'display':'평가 다운로드 설정'},
          {'value':'regression_down', 'display':'리그레션 다운로드'},
          {'value':'threshold', 'display':'Threshold 설정'},
          {'value':'confusion_matrix', 'display':'Confusion Matrix'},
        ],
        sortItems:[
          {'value': 1, 'display': 'Low'},
          {'value': -1, 'display': 'High'}
        ],
      }
    },
    props: {
      total: Number,
      // desSearch: String,
      // pagination: {},
      snapshotItems: Array,
      selectIntent: String,
      desSearch: String,
      correctSelected: Number,
      intentId: String,
      pageCount: Number,
      firstIntentSortSelected: Number,
      selectedUtterance: Array,
    },
    watch: {
      pagination (value) {

        value.change = false
        this.$emit('paging', value)
      },
      intentId : {
        handler(n,o){
            if( n !== o ){
                // 해당 조회하는 인텐트가 변경이 되었을때, 초기 커런트 페이지를 1로 되돌린다.
                this.page = 1;
            }
        },
        deep : true
      }
    },
    methods: {
      textCreate() {
        if (this.desAdd != "") {
          this.$emit('textCreate', this.desAdd)
        }
      },
      handlePageSizeChange(size) {
        this.pageSize = size;
        this.page = 1;
        this.textSearch();
      },
      handlePageChange(value) {
        this.page = value;
        this.textSearch();
      },
      handleOtherFuncChange(item) {
        if (item.value === "train_down") {
          this.excelDown('train')
        } else if (item.value === "test_down") {
          this.excelDown('test')
        } else if (item.value === "test_down_with_verification") {
          this.excelDown('test', true)
        } else if (item.value == "show_test_download_setting_dialog") {
          this.showTestDownloadSettingDialog()
        } else if (item.value === "regression_down") {
          this.excelDown('regression')
        } else if (item.value === "threshold") {
          this.threshold_setting()
        } else if (item.value === "confusion_matrix") {
          this.confusionMatrixCall()
        }
      },
      textSearch() {
        this.$emit('textSearch', this.desSearch, this.correctSelected, this.page, this.pageSize, this.firstIntentSortSelected)
      },
      moveModel() {
        this.$emit('moveModel')
      },
      moveToTrain() {
        this.$emit('moveToTrain')
      },
      clicked(value) {
        console.log(value)
        this.confidenceItems = []
        this.confidenceItems = value.item.confidence_list
      },
      customSort: function(items, index, isDesc) {
        console.log(items, index, isDesc)
      },
      excelDown(type, include_verification=false) {
        const typeStr = {'train': '학습', 'test': '평가', 'regression': '리그레션'}
        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
            this.$emit('excelDown', type, include_verification);
          },
          text : typeStr[type] + " 데이터를 다운로드 하시겠습니까?"
        })
      },
      rule_setting() {
        this.$emit('rule_setting')
      },
      showTestDownloadSettingDialog() {
        this.$emit('showTestDownloadSettingDialog')
      },
      confusionMatrixCall() {
        this.$emit('confusionMatrixCall')
      },
      threshold_setting() {
        this.$emit('threshold_setting')
      },
      intentView(item) {
        console.log(item.intent_aiu_data)
      },

      // getItemPerPage(val) {
      //   console.log(val)
      // },
    },
    computed : {
      selected: {
        get () {
          return this.selectedUtterance
        },
        set (newVal) {
          this.$emit('updateSelectedUtterance', newVal)
        }
      },

      totalCount () {
        return this.total
      }
    },
  }
</script>
