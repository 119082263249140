
<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    max-width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">
        <span>Threshold 설정</span>
      </v-card-title>
      <v-card-text class="text-xs-left subheading">
        <v-container>
          <div>* 일괄 변경</div>
          <v-flex>
            <v-row>
              <v-col sm="2" style="padding-bottom: 0px;">
                <v-flex>
                <v-text-field
                  :label="'ebot'"
                  v-model="ebotItem"
                ></v-text-field>
                </v-flex>
              </v-col>
              <v-col sm="2" style="padding-bottom: 0px;">
                <v-text-field
                  :label="'pbot'"
                  v-model="pbotItem"
                ></v-text-field>
              </v-col>
              <v-col sm="1" style="padding-bottom: 0px;">
                <v-btn
                  color="primary"
                  @click="blocClick"
                >일괄적용</v-btn>
              </v-col>
            </v-row>
          </v-flex>
          <div></div>
          <div>* threshold 값은 0 ~ 1 사이에 값으로 수정 가능합니다.</div>
          <div>* pbot threshold가 수정되지 않았다면 저장시 현재 상태 그대로 적용됩니다</div>
          <v-flex>

            <vue-editable-grid
              class="my-grid-class"
              ref="grid"
              id="mygrid"
              :column-defs="columnDefs"
              :row-data="threshold_rows"
              row-data-key='intent'
            ></vue-editable-grid>
          </v-flex>
          <v-flex>

          </v-flex>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="save"
        >
          저장
        </v-btn>
        <v-btn
          color="primary"
          @click="close"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>


  import VueEditableGrid from 'vue-editable-grid'

  // import VgtPagination from '@/components/grid/VgtPagination.vue';
  // import VgtGlobalSearch from '@/components/grid/VgtGlobalSearch.vue';
  // import VgtTableHeader from '@/components/grid/VgtTableHeader.vue';
  // import VgtHeaderRow from '@/components/grid/VgtHeaderRow.vue';
  // import ConfusionDataDialog from '@/components/common/ConfusionDataDialog.vue'

  export default {
    components: {
      VueEditableGrid
      // VgtPagination,
      // VgtGlobalSearch,
      // VgtHeaderRow,
      // VgtTableHeader,
    },
    data () {
      return {
        columnDefs: [
          { sortable: false, filter: true, field: 'intent', headerName: 'intent', editable: false},
          { sortable: false, filter: false, field: 'ebot_threshold', headerName: 'ebot threshold', editable: true},
          { sortable: false, filter: false, field: 'pbot_threshold', headerName: 'pbot threshold', editable: true},
        ],
        rows: [],
        pbotItem: '0.0',
        ebotItem: '0.0',
        cnt: 0,
        series: [],

      }
    },
    created () {
      console.log('created')
      this.rows = this.threshold_rows

    },
    mounted () {
      // for (let i = 0; i < 12; i++) {
      //   this.params.data.push([i+1, `${random()}`, `${random()}`, `${random()}`])
      // }
      console.log('mounted')
      console.log(this.headers)
      // console.log(this.headers)
      // this.headers.forEach(d => {
      //     d['tdClass'] = this.tdClassFunc
      //   })
    },
    props: {
      threshold_rows: Array,
      headers: Array,
      // xdataSource: Array,
      xLabels: Array,
      icon: String,
      message: String,
      dialog: Boolean
    },


    methods: {
      cellUpdated() {

      },
      rowSelected() {

      },
      linkClicked(){},
      blocClick() {
        //console.log(this.threshold_rows)

        Object.values(this.threshold_rows).forEach( ( obj , index ) => {
          obj['ebot_threshold'] = this.ebotItem
          obj['pbot_threshold'] = this.pbotItem
        });
            // if( obj.botid == this.botId ){
            //   this.BotSelected = obj.fullname;
            //   this.botChange(true);
            // }
      },
      customRowClass (item) {
        console.log(item)
        return 'custom-bg'
      } ,
      close () {
        // console.log(this.yALabels)
        this.threshold_rows = []
        this.$emit('close', false)
      },
      save() {
        this.$emit('threshold_save', this.threshold_rows)
      },
    }
  }
</script>
<style>
  .red-class {
    color: 'red'
  }
  .test-class {
    background-color: #F3F3F3
  }
  .level1-class {

  }
  .level2-class {

  }
  .level3-class {

  }
  /* .true-class {
    background-color: 'green'
  }   */
  .my-grid-class {
    height: 400px;
  }
</style>
