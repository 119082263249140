<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title class="headline">
        <v-icon color="primary" class="mr-2" medium>{{ icon }}</v-icon>{{ title }}
      </v-card-title>
      <v-card-text v-html="message" class="text-xs-left subheading"/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="close"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: String,
    icon: String,
    message: String,
    dialog: Boolean
  },
  methods: {
    close () {
      this.$emit('close', false)
    }
  }
}
</script>
