var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.botItems,"options":_vm.pagination,"items-per-page":10,"server-items-length":_vm.total,"expand":_vm.expand,"item-key":"_id","single-expand":"","show-expand":"","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event},"item-expanded":_vm.clicked,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.valid_check",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"teal darken-3"},on:{"click":function($event){return _vm.deployValidCheck(item, 'parent')}}},[_c('v-icon',{attrs:{"left":"","color":"teal darken-3"}},[_vm._v("mdi-update")]),_c('span',[_vm._v("API 테스트")])],1)]}},{key:"item.deploy_delete",fn:function(ref){
var item = ref.item;
return [(item.deploy == true)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"deep-orange darken-1"},on:{"click":function($event){return _vm.deployDelete(item, 'parent')}}},[_c('v-icon',{attrs:{"left":"","color":"deep-orange darken-1"}},[_vm._v("mdi-cancel")]),_c('span',[_vm._v("배포 삭제")])],1):_vm._e()]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
return [(item.deploy == true && !item.service_confirm)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"light-blue darken-4"},on:{"click":function($event){return _vm.serviceConfirm(item)}}},[_c('v-icon',{attrs:{"left":"","color":"light-blue darken-4"}},[_vm._v("mdi-cloud-upload")]),_c('span',[_vm._v("서비스 반영")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.deploy == true && item.service_confirm)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":function($event){return _vm.serviceDelete(item)}}},[_c('v-icon',{attrs:{"left":"","color":"grey darken-2"}},[_vm._v("mdi-cancel")]),_c('span',[_vm._v("서비스 삭제")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-flex',{attrs:{"pa-4":"","grey":"","lighten-4":""}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.childBotHeaders,"items":_vm.childBotItems,"item-key":"_id","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"dark":"","color":"#59493B"}},[_vm._v(" 서비스봇 리스트 ")])]},proxy:true},{key:"item.valid_check",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"teal darken-3"},on:{"click":function($event){return _vm.deployValidCheck(item, 'parent')}}},[_c('v-icon',{attrs:{"left":"","color":"teal darken-3"}},[_vm._v("mdi-update")]),_c('span',[_vm._v("API 테스트")])],1)]}},{key:"item.deploy_delete",fn:function(ref){
var item = ref.item;
return [(item.deploy == true)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"deep-orange darken-1"},on:{"click":function($event){return _vm.deployDelete(item, 'parent')}}},[_c('v-icon',{attrs:{"left":"","color":"deep-orange darken-1"}},[_vm._v("mdi-cancel")]),_c('span',[_vm._v("배포 삭제")])],1):_vm._e()]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
return [(item.deploy == true && !item.service_confirm)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"light-blue darken-4"},on:{"click":function($event){return _vm.serviceConfirm(item)}}},[_c('v-icon',{attrs:{"left":"","color":"light-blue darken-4"}},[_vm._v("mdi-cloud-upload")]),_c('span',[_vm._v("서비스 반영")])],1):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.deploy == true && item.service_confirm)?_c('v-btn',{staticClass:"bot-list-btn",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":function($event){return _vm.serviceDelete(item)}}},[_c('v-icon',{attrs:{"left":"","color":"grey darken-2"}},[_vm._v("mdi-cancel")]),_c('span',[_vm._v("서비스 삭제")])],1):_vm._e()]}}],null,true)})],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pagination_wrapper"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":_vm.limitPageVisible},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }