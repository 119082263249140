<template>
  <v-card
  >
    <v-toolbar
      flat
    >
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="#59493B"
        dark
      >
        <v-tabs-slider color="#C4F2AE"></v-tabs-slider>
          <v-tab
            v-model="type_active"
            v-for="subTabItem in subTabdata"
            :key="subTabItem.id"
            @click="changeRoute(subTabItem.id)"
          >
            <span class="title">{{ subTabItem.name }}</span>
          </v-tab>
          <v-spacer></v-spacer>
          <div md="10" class="pt-3">
<!--             {{ params.botid }} ({{ params.botname }})-->
            <v-select
              v-model="BotSelected"
              v-on:change="botChange(true)"
              :items="botItems"
              hide-details
              item-text="fullname"
              items-value="botid"
              outlined
              dense
              label="Bot 선택"
              clearable
            >
            </v-select>
          </div>
          <v-col md="2" class="pt-3">
            <v-file-input
              v-model="files"
              chips
              label="*train.txt, *valid.txt, *test.txt 파일을 선택해 주세요"
              outlined
              dense
              solo-inverted
              hide-details
            >
            </v-file-input>
          </v-col>
          <v-btn color="#A68C76" class="mt-3 mr-12" width="120" @click="csv_upload('remain')">
            <span>파일 등록</span>
            <v-divider class="mx-2" vertical></v-divider>
            <v-menu dense offset-y left nudge-right="10" nudge-bottom="7" >
              <template v-slot:activator="{ on }">
                <v-toolbar-title v-on="on">
                  <v-icon dark>arrow_drop_down</v-icon>
                </v-toolbar-title>
              </template>
              <v-list dense>
                <v-list-item v-for="method in upload_methods" :key="method.type" @click="csv_upload(method.type)">
                  <v-list-item-title v-text="method.desc"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
          <v-btn color="#A68C76" class="px-5 mt-3 mr-3" width="80" @click="botStatus = !botStatus">botid 변경</v-btn>
          <template v-if="tab === 3">
            <v-btn color="#A68C76" class="px-9 mt-3 mr-3" width="80" @click="showRegressionPopup()">테스트</v-btn>
          </template>
          <template v-else>
            <v-btn color="#A68C76" class="px-9 mt-3 mr-3" width="80" @click="trainSender()">학습</v-btn>
          </template>
          <!-- <v-btn color="#A68C76" class="px-9 mt-3 mr-6" @click="modalTest()">모달테스트</v-btn> -->
          <v-btn color="#A68C76" class="px-9 mt-3 mr-6" width="80" @click="dataDelete()">전체삭제</v-btn>
          <!-- <v-btn color="#A68C76" class="px-9 mt-3 mr-6" @click="movePage()">임시</v-btn> -->
      </v-tabs>
    </v-toolbar>
    <!-- intent & table 바인딩 -->
    <v-tabs-items v-model="tab">
      <!-- 학습셋 -->
      <v-tab-item>
        <v-main v-show="botStatus == false">
          <v-layout align-center justify-center>
          <v-col md="3" class="pt-1">
            <v-select
              v-model="BotSelected"
              v-on:change="botChange(true)"
              :items="botItems"
              hide-details
              item-text="fullname"
              items-value="botid"
              outlined
              dense
              label="Bot 선택"
              clearable
            >
            </v-select>
          </v-col>
          </v-layout>
        </v-main>
        <v-main class="pt-0 pb-0" v-show="botStatus == true">
          <splitpanes class="main_wrapper">
            <pane size="20" min-size="20">
              <v-navigation-drawer
                :right="false"
                overflow
                permanent
                style="width: 100%;"
              >
                <v-list
                  dense
                  nav
                  class="py-0"
                >
                  <v-list-item>
                    <v-col class="pr-0 pl-0" style="height:61px">
                      <v-text-field
                        v-model="intentName"
                        label="Intent 필터/등록"
                        outlined
                        dense
                        @keydown.enter="intentCreate"
                      ></v-text-field>
                    </v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon text
                               color="blue-grey darken-2"
                               @click="changeIntentSort">
                          <v-icon large v-if="!intentSortByRegTime">mdi-alpha-a</v-icon>
                          <v-icon v-if="intentSortByRegTime">mdi-clock-outline</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="!intentSortByRegTime">이름 정렬</span>
                      <span v-if="intentSortByRegTime">등록 시간 정렬</span>
                    </v-tooltip>
                    <v-btn color="#D9CDBF" @click="intentCreate()">추가</v-btn>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item-group
                    v-model="train_active"
                    mandatory color="brown darken-2"
                  >
                  <v-list-item
                    v-for="item in filteredTrainIntentItems"
                    :key="item._id"
                    link
                  >
                    <v-list-item-content class="pt-0 pb-0">
                      <v-list-item-title
                        @click="DataBind(item)"
                        class="pt-3"
                        role="button"
                        style="height:38px;">
                          <div v-if="predefined_set[item.botid][item.title] != undefined">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="intent-name"
                                >
                                  {{ item.title }}
                                </span>
                              </template>
                              <span color="green">{{ item.title }}</span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="intent-name"
                                >
                                  {{ item.title }}
                                </span>
                              </template>
                              <span color="" class="test">{{ item.title }}</span>
                            </v-tooltip>
                          </div>

                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="addPredefined(item)">add</v-icon></v-btn>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="editIntent(item)">edit</v-icon></v-btn>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="deleteIntent(item)">delete</v-icon></v-btn>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </pane>
            <pane>
              <train-data-list
                :intentId="params['intent_id']"
                :trainDataItems="trainDataItems"
                :total="total"
                :desSearch="desSearch"
                :typeName="typeName"
                :page="page"
                :pageCount="pageCount"
                @updateDesSearch="updateDesSearch"
                @paging="paging"
                @textCreate="textCreate"
                @textSearch="textSearch"
                @editDescripion="editDescripion"
                @delDescription="delDescription"
                @eachDelete="eachDelete"
                @eachDownload="eachDownload"
              >
              </train-data-list>
            </pane>
          </splitpanes>
        </v-main>
        <!-- 끝 -->
      </v-tab-item>
      <!-- 학습셋 완료-->
      <!-- 개발셋-->
      <v-tab-item>
        <!-- 봇 선택 -->
        <v-main v-show="botStatus == false">
          <v-layout align-center justify-center>
          <v-col md="3" class="pt-1">
            <v-select
              v-model="BotSelected"
              :items="botItems"
              v-on:change="botChange()"
              item-text="fullname"
              items-value="parent_botid"
              outlined
              dense
              label="Bot 선택"
              clearable
            >
            </v-select>
          </v-col>

          </v-layout>
        </v-main>
        <!-- 봇 선택 후 데이터 바인딩 -->
        <v-main class="pt-0 pb-0" v-show="botStatus == true">
          <splitpanes class="main_wrapper">
            <pane size="20" min-size="20">
              <v-navigation-drawer
                :right="false"
                overflow
                permanent
                style="width: 100%;"
              >
                <v-list
                  dense
                  nav
                  class="py-0"
                >
                  <v-list-item>
                    <v-col class="pr-0 pl-0" style="height:61px">
                      <v-text-field
                        v-model="intentName"
                        label="Intent 필터/등록"
                        outlined
                        dense
                        @keydown.enter="intentCreate"
                      ></v-text-field>
                    </v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon
                               color="blue-grey darken-2"
                               @click="changeIntentSort">
                          <v-icon large v-if="!intentSortByRegTime">mdi-alpha-a</v-icon>
                          <v-icon v-if="intentSortByRegTime">mdi-clock-outline</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="!intentSortByRegTime">이름 정렬</span>
                      <span v-if="intentSortByRegTime">등록 시간 정렬</span>
                    </v-tooltip>
                    <v-btn color="#D9CDBF" @click="intentCreate()">추가</v-btn>
                    <!-- <v-btn color="#A68C76" v-else>수정</v-btn> -->
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item-group
                    mandatory color="brown darken-2"
                    v-model="valid_active"
                  >
                  <v-list-item
                    v-for="item in filteredValidIntentItems"
                    :key="item._id"
                    link
                  >
                    <v-list-item-content class="pt-0 pb-0">
                      <v-list-item-title
                        @click="DataBind(item)"
                        active
                        class="pt-3"
                        style="height:38px;"
                        role="button"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="intent-name"
                            >
                              {{ item.title }}
                            </span>
                          </template>
                          <span>{{ item.title }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="editIntent(item)">edit</v-icon></v-btn>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="deleteIntent(item)">delete</v-icon></v-btn>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </pane>
            <pane>
              <train-data-list
                :intentId="params['intent_id']"
                :trainDataItems="trainDataItems"
                :page:="page"
                :total="total"
                :desSearch="desSearch"
                :typeName="typeName"
                :page="page"
                :pageCount="pageCount"
                @updateDesSearch="updateDesSearch"
                @paging="paging"
                @textCreate="textCreate"
                @textSearch="textSearch"
                @editDescripion="editDescripion"
                @delDescription="delDescription"
                @eachDelete="eachDelete"
                @eachDownload="eachDownload"
              >
              </train-data-list>
            </pane>
          </splitpanes>
        </v-main>
        <!-- 끝 -->
      </v-tab-item>
      <!-- 개발셋 완료-->
      <!-- 평가셋-->
      <v-tab-item>
        <!-- 봇 선택 -->
        <v-main v-show="botStatus == false">
          <v-layout align-center justify-center>
          <v-col md="3" class="pt-1">
            <v-select
              v-model="BotSelected"
              :items="botItems"
              v-on:change="botChange()"
              item-text="fullname"
              items-value="parent_botid"
              outlined
              dense
              label="Bot 선택"
              clearable
            >
            </v-select>
          </v-col>

          </v-layout>
        </v-main>
        <!-- 봇 선택 후 데이터 바인딩 -->
        <v-main class="pt-0 pb-0" v-show="botStatus == true">
          <splitpanes class="main_wrapper">
            <pane size="20" min-size="20">
              <v-navigation-drawer
                :right="false"
                overflow
                permanent
                style="width: 100%;"
              >
                <v-list
                  dense
                  nav
                  class="py-0"
                >
                  <v-list-item>
                    <v-col class="pr-0 pl-0" style="height:61px">
                      <v-text-field
                        v-model="intentName"
                        label="Intent 필터/등록"
                        outlined
                        dense
                        @keydown.enter="intentCreate"
                      ></v-text-field>
                    </v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon
                               color="blue-grey darken-2"
                               @click="changeIntentSort">
                          <v-icon large v-if="!intentSortByRegTime">mdi-alpha-a</v-icon>
                          <v-icon v-if="intentSortByRegTime">mdi-clock-outline</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="!intentSortByRegTime">이름 정렬</span>
                      <span v-if="intentSortByRegTime">등록 시간 정렬</span>
                    </v-tooltip>
                    <v-btn color="#D9CDBF" @click="intentCreate()">추가</v-btn>
                    <!-- <v-btn color="#A68C76" v-else>수정</v-btn> -->
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item-group
                    mandatory color="brown darken-2"
                    v-model="test_active"
                  >
                  <v-list-item
                    v-for="item in filteredTestIntentItems"
                    :key="item._id"
                    link
                  >
                    <v-list-item-content class="pt-0 pb-0">
                      <v-list-item-title
                        @click="DataBind(item)"
                        active
                        class="pt-3"
                        style="height:38px;"
                        role="button"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="intent-name"
                            >
                              {{ item.title }}
                            </span>
                          </template>
                          <span>{{ item.title }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="editIntent(item)">edit</v-icon></v-btn>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="deleteIntent(item)">delete</v-icon></v-btn>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </pane>
            <pane>
              <train-data-list
                :intentId="params['intent_id']"
                :trainDataItems="trainDataItems"
                :total="total"
                :page:="page"
                :desSearch="desSearch"
                :typeName="typeName"
                :page="page"
                :pageCount="pageCount"
                @updateDesSearch="updateDesSearch"
                @paging="paging"
                @textCreate="textCreate"
                @textSearch="textSearch"
                @editDescripion="editDescripion"
                @delDescription="delDescription"
                @eachDelete="eachDelete"
                @eachDownload="eachDownload"
              >
              </train-data-list>
            </pane>
          </splitpanes>
        </v-main>
        <!-- 끝 -->
      </v-tab-item>
      <!-- 평가셋 완료 -->
      <!-- regression test -->
      <v-tab-item>
        <!-- 봇 선택 -->
        <v-main v-show="botStatus == false">
          <v-layout align-center justify-center>
          <v-col md="3" class="pt-1">
            <v-select
              v-model="BotSelected"
              :items="botItems"
              v-on:change="botChange()"
              item-text="fullname"
              items-value="parent_botid"
              outlined
              dense
              label="Bot 선택"
              clearable
            >
            </v-select>
          </v-col>

          </v-layout>
        </v-main>
        <!-- 봇 선택 후 데이터 바인딩 -->
        <v-main class="pt-0 pb-0" v-show="botStatus == true">
          <splitpanes class="main_wrapper">
            <pane size="20" min-size="20">
              <v-navigation-drawer
                :right="false"
                overflow
                permanent
                style="width: 100%;"
              >
                <v-list
                  dense
                  nav
                  class="py-0"
                >
                  <v-list-item>
                    <v-col class="pr-0 pl-0" style="height:61px">
                      <v-text-field
                        v-model="intentName"
                        label="Intent 필터/등록"
                        outlined
                        dense
                        @keydown.enter="intentCreate"
                      ></v-text-field>
                    </v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               icon
                               color="blue-grey darken-2"
                               @click="changeIntentSort">
                          <v-icon large v-if="!intentSortByRegTime">mdi-alpha-a</v-icon>
                          <v-icon v-if="intentSortByRegTime">mdi-clock-outline</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="!intentSortByRegTime">이름 정렬</span>
                      <span v-if="intentSortByRegTime">등록 시간 정렬</span>
                    </v-tooltip>
                    <v-btn color="#D9CDBF" @click="intentCreate()">추가</v-btn>
                    <!-- <v-btn color="#A68C76" v-else>수정</v-btn> -->
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-list-item-group
                    mandatory color="brown darken-2"
                    v-model="test_active"
                  >
                  <v-list-item
                    v-for="item in filteredRegressionIntentItems"
                    :key="item._id"
                    link
                  >
                    <v-list-item-content class="pt-0 pb-0">
                      <v-list-item-title
                        @click="DataBind(item)"
                        active
                        class="pt-3"
                        style="height:38px;"
                        role="button"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="intent-name"
                            >
                              {{ item.title }}
                            </span>
                          </template>
                          <span>{{ item.title }}</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="editIntent(item)">edit</v-icon></v-btn>
                    <v-btn v-if="item.title !== '[ALL]'" icon><v-icon small @click="deleteIntent(item)">delete</v-icon></v-btn>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </pane>
            <pane>
              <train-data-list
                :intentId="params['intent_id']"
                :trainDataItems="trainDataItems"
                :total="total"
                :page:="page"
                :desSearch="desSearch"
                :typeName="typeName"
                :page="page"
                :pageCount="pageCount"
                @updateDesSearch="updateDesSearch"
                @paging="paging"
                @textCreate="textCreate"
                @textSearch="textSearch"
                @editDescripion="editDescripion"
                @delDescription="delDescription"
                @eachDelete="eachDelete"
                @eachDownload="eachDownload"
              >
              </train-data-list>
            </pane>
          </splitpanes>
        </v-main>
        <!-- 끝 -->
      </v-tab-item>
      <!-- regression test 완료 -->
      <!-- 기구축 학습셋 -->
      <v-tab-item>
        <v-main class="pt-0 pb-0">
          <v-navigation-drawer
            :right="false"
            absolute
            :width="300"
            overflow
          >
            <v-list
              dense
              nav
              class="py-0"
            >


              <v-divider></v-divider>
              <v-list-item-group
                v-model="reject_active"
                mandatory color="brown darken-2"
              >
              <v-list-item

                v-for="item in rejectIntentItems"
                :key="item._id"
                link
              >
                <v-list-item-content class="pt-0 pb-0">
                  <!-- <v-checkbox
                    :key="item.intent"
                    :label="item.intent_text"
                    :value="item.intent"
                  ></v-checkbox> -->
                  <v-list-item-title
                    @click="getRejectDataBind(item)"
                    role="button"
                    class="pt-3"
                    style="height:38px;">
                      {{ item.corpus_id }}
                  </v-list-item-title>
                </v-list-item-content>

              </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
          <reject-data-list
            :rejectItems="rejectItems"
            :total="reject_total"
            :desSearch="desSearch"
            @rejectPaging="rejectPaging"
            @textSearch="textSearch"
          >
          </reject-data-list>
        </v-main>
      </v-tab-item>
      <!-- 기구축 학습셋 end -->

      <!-- 통계 -->
      <v-tab-item>
        <!-- 봇 선택 -->
        <v-main v-show="botStatus == false">
          <v-layout align-center justify-center>
            <v-col md="3" class="pt-1">
              <v-select
                v-model="BotSelected"
                :items="botItems"
                v-on:change="botChange()"
                item-text="fullname"
                items-value="parent_botid"
                outlined
                dense
                label="Bot 선택"
                clearable
              >
              </v-select>
            </v-col>
          </v-layout>
        </v-main>
        <!-- 봇 선택 후 데이터 바인딩 -->
        <v-main class="pt-0 pb-0" v-show="botStatus == true">
          <v-layout row wrap pa-4>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option1"
                  :series="chart_series1"
                ></apexchart>
              </v-flex>
            </v-col>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option4"
                  :series="chart_series4"
                ></apexchart>
              </v-flex>
            </v-col>
          </v-layout>
          <v-layout row wrap pa-4>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option2"
                  :series="chart_series2"
                ></apexchart>
              </v-flex>
            </v-col>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option5"
                  :series="chart_series5"
                ></apexchart>
              </v-flex>
            </v-col>
          </v-layout>
          <v-layout row wrap pa-4>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option3"
                  :series="chart_series3"
                ></apexchart>
              </v-flex>
            </v-col>
            <v-col md="6">
              <v-flex>
                <apexchart
                  type="bar"
                  height="350"
                  :options="chart_option6"
                  :series="chart_series6"
                ></apexchart>
              </v-flex>
            </v-col>
          </v-layout>
        </v-main>
        <!-- 끝 -->
      </v-tab-item>
      <!-- 통계 완료 -->
    </v-tabs-items>

    <v-layout row justify-center>
      <v-dialog
        v-model="updateDialog['isShow']"
        max-width="580"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="primary" class="mr-2" medium></v-icon>
            <span>{{ updateDialog['name'] }} {{ updateDialog['type'] }}</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">
            <v-container>
              <v-layout column>
                <v-flex>
                  <v-text-field
                    :label="updateDialog['label']"
                    v-model="update_text"
                    @keydown.enter="updateConfirm"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="updateDialog['isShow'] = false"
            >
              취소
            </v-btn>
            <v-btn
              color="primary"
              @click="updateConfirm"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="regressionDialog['isShow']"
        max-width="500"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="primary" class="mr-2" medium>{{ regressionDialog['icon'] }}</v-icon>
            <span>Regression Test</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">
            <v-container>
              <v-layout column>
                <v-flex>
                  <v-select
                    v-model="regressionDialog['trainResultItemId']"
                    :items="trainResultItems"
                    item-text="version"
                    items-value="version"
                    label="학습결과 버전"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="regressionDialog['isShow'] = false"
            >
              취소
            </v-btn>
            <v-btn
              color="primary"
              @click="regressionTest"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="senderDialog['isShow']"
        max-width="600"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="secondry" class="mr-2" medium>info</v-icon>
            <span>학습하기</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">{{ senderDialog['message'] }}</v-card-text>
            <v-container>
              <v-layout column>
                <v-flex>
                  <v-text-field
                    readonly
                    :label="'법인'"
                    v-model="senderDialog['customer']"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field
                    readonly
                    :label="'botid'"
                    v-model="senderDialog['botid']"
                  ></v-text-field>
                </v-flex>
                <v-layout row wrap pa-4>
                  <v-col md="4" class="pt-3">
                    <v-text-field
                      readonly
                      :label="'학습셋 갯수'"
                      v-model="senderDialog['train_count']"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="pt-3">
                    <v-text-field
                      readonly
                      :label="'개발셋 갯수'"
                      v-model="senderDialog['valid_count']"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="pt-3">
                    <v-text-field
                      readonly
                      :label="'평가셋 갯수'"
                      v-model="senderDialog['test_count']"
                    ></v-text-field>
                  </v-col>
                </v-layout>
                <!-- <v-flex>
                  <span class="font-italic"> * 확인사항 : </span>
                </v-flex> -->
                <v-flex>
                  <v-select
                    v-model="senderDialog['model_type']"
                    :items="modelTypeItems"
                    item-text="name"
                    items-value="id"
                    label="모델 타입"
                  ></v-select>
                </v-flex>
                <v-flex>
                  <v-radio-group
                    v-model="senderDialog['outflip']"
                    label="OutFlip 설정"
                    row
                  >
                    <v-radio
                      label="on"
                      value="on"
                    ></v-radio>
                    <v-radio
                      label="off"
                      value="off"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex>
                  <v-text-field
                    readonly
                    :label="'기구축학습셋 선택항목'"
                    v-model="senderDialog['predefined_set']"
                  ></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field
                    :label="'description 입력'"
                    v-model="senderDialog['description_txt']"
                  ></v-text-field>
                </v-flex>
                <v-radio-group  column v-if="senderDialog['valid_count'] === 0" v-model="senderDialog['method_for_valid']">
                  <v-radio label="평가셋을 개발셋으로 간주하고 학습을 진행" value="use_test_as_valid"></v-radio>
                  <v-radio label="학습, 개발, 평가 데이터를 모두 합해서 학습을 진행" value="use_all"></v-radio>
                  <v-radio label="개발셋을 별도 설정하지 않고 학습데이터만으로 학습을 진행" value="default"></v-radio>
                </v-radio-group>
                <v-radio-group  column v-else  v-model="senderDialog['method_for_valid']">
                  <v-radio label="개발셋 데이터를 포함한 학습을 진행" value="use_valid" ></v-radio>
                  <v-radio label="학습, 개발, 평가 데이터를 모두 합해서 학습을 진행" value="use_all"></v-radio>
                  <v-radio label="개발셋을 별도 설정하지 않고 학습데이터만으로 학습을 진행" value="default"></v-radio>
                </v-radio-group>
              </v-layout>
            </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondry"
              @click="senderDialog['isShow'] = false"
            >
              취소
            </v-btn>
            <v-btn
              color="secondry"
              @click="trainSenderOK"
              @disabled="trainBtnDisabled"
            >
              학습하기
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
      <v-dialog
        v-model="deleteDialog['isShow']"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="secondry" class="mr-2" medium>info</v-icon>
            <span>데이터 삭제</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">{{ deleteDialog['message'] }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondry"
              @click="deleteDialog['isShow'] = false"
            >
              취소
            </v-btn>
            <v-btn
              color="secondry"
              @click="deleteOK"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="predefinedDialog['isShow']"
        max-width="580"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="primary" class="mr-2" medium>{{ predefinedDialog['icon'] }}</v-icon>
            <span>{{ predefinedDialog['name'] }} {{ predefinedDialog['type'] }}</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">
            <v-container>
              <v-layout column>
                <v-flex>
                  <v-text-field
                    readonly
                    :label="'intent'"
                    v-model="vIntent"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout column>
                <v-flex>
                  <v-select
                    v-model="vCorpus_id"
                    :items="predefinedItems"
                    item-text="corpus_id"
                    items-value="corpus_id"
                    multiple
                    chips
                    label="기구축학습셋"
                    clearable
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="predefinedDialog['isShow'] = false"
            >
              취소
            </v-btn>
            <v-btn
              color="primary"
              @click="updatePredefined"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>


    <progress-dialog
      :dialog="progressDialog['isShow']"
      :title="progressDialog['title']"
    ></progress-dialog>
    <alert-dialog
      :dialog="alertDialog['isShow']"
      :title="alertDialog['title']"
      :message="alertDialog['message']"
      :icon="alertDialog['icon']"
      @close="alertDialog['isShow'] = false"
    ></alert-dialog>


    <confirm-dialog
      :dialog="confirmDialog['isShow']"
      :title="confirmDialog['title']"
      :message="confirmDialog['message']"
      :icon="confirmDialog['icon']"
      @confirmOK="confirmOK"
      @confirmNO="confirmNO"
      @close="confirmDialog['isShow'] = false"
    ></confirm-dialog>
  </v-card>

</template>

<script>
import { decodeToken, getCookie, deleteCookie, globalCatch} from '@/utils'
import TrainDataList from '@/components/TrainData/TrainDataList'
import RejectDataList from '@/components/TrainData/RejectDataList'

import ProgressDialog from '@/components/common/ProgressDialog.vue'
import AlertDialog from '@/components/common/AlertDialog.vue'


import VueApexCharts from 'vue-apexcharts'
import ConfirmDialog from "@/components/common/ConfirmDialog";
import {mapMutations, mapState} from "vuex";

// Split Pane
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

const { BOT_COMBO, BOT_LIST } = process.env;

  export default {

    name : "TranDataContainer",
    props : ["botId"],
    components: {
      ConfirmDialog,
      AlertDialog,
      TrainDataList,
      RejectDataList,
      ProgressDialog,

      Splitpanes,
      Pane
    },
    created () {
      ( async () => {
        await this.botList();
        await this.predefinedList();
        this.predefined_set = {}
        // 기존 데이터 봇 정보 조회
        if (this.botItem.fullname) {
          this.BotSelected = this.botItem.fullname
          this.botChange(true);
        }
        // FIXME 이거 실행되나?
        if(this.botId){
          // 봇 선택 한채로 새로고침 시 해당 아규먼츠 기준대로 데이터를 재 로딩시킨다.
          Object.values(this.botItems).forEach( ( obj , index ) => {
            if( obj.botid == this.botId ){
              this.BotSelected = obj.fullname;
              this.botChange(true);
            }
          });


        }else{
          // 봇 선택 하지 않을 경우 예정대로 봇 데이터 선택화면과 리스트를 호출한다.
          // this.$store.state.bot.id = ''
          // this.predefined_set = {}
        }
      })()

    },
    beforeRouteUpdate (to , from , next){
      const { params } = to;
      if(!params.botId){
        // 라우터 쿼리가 변경됨에 따라 재 메뉴 진입으로 하기 봇상태 설정을 변경
        this.botStatus = false;
        // this.BotSelected =  "";
      }
      next();
    },
    data () {
      return {
        confirmData: false,
        page: 1,
        type_active: "train",
        textDesc: "",
        intentAll: false,
        BotSelected: '',
        train_active: 0,
        test_active: 0,
        valid_active: 0,
        reject_active: 0,
        stat_active: 0,
        botStatus : false,
        bind_type: false,
        type: 'train',
        botSelect : '',
        reject_total: 0,
        files: null,
        drawer: true,
        tab: null,
        typeName: "train",
        intentName : '',
        intentCreating: false,
        intentSortByRegTime: true,

        trainResultItems: [],
        trainDataItems: [],
        total: 0,
        desSearch: null,
        right: true,
        trainIntentItems: [],
        testIntentItems: [],
        validIntentItems: [],
        regressionIntentItems: [],
        predefinedItems: [],
        rejectIntentItems: [],
        rejectItems: [],
        subTabItems : [],
        reject_check_list: [],
        modelTypeItems: [
          { id: 'basic', name:'basic'},
          { id: 'advanced(m)', name:'advanced(m)'},
          { id: 'advanced(e)', name:'advanced(e)'},
          { id: 'premium(m)', name:'premium(m)'},
          { id: 'premium(e)', name:'premium(e)'},
        ],
        subTabdata : [
          { id: 'train', name:'학습셋'},
          { id: 'valid', name:'개발셋'},
          { id: 'test', name:'평가셋'},
          { id: 'regression', name:'Regression Test'},
          { id: 'reject', name:'기구축학습셋'},
          { id: 'stat', name:'통계'},
        ],
        botItems:[],
        params: {
          customer: '',
          type: 'train',
          botid: '',
          botname: '',
          text: null,
        },
        reject_params: {
        },
        initPage : {
          page: 1,
          itemsPerPage: 100,
          change: false,
        },
        pageCount: 1,
        updateDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
          ttl: null,
          description: null
        },
        senderDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
          ttl: null,
          description: null,
          method_for_valid: 'default',
          model_type: 'basic',
          outflip: 'off',
        },
        regressionDialog: {
          isShow: false,
          name: null,
          type: null,
          label: null,
          ttl: null,
          description: null,
          method_for_valid: 'default',
          model_type: 'basic',
          outflip: 'off',
          trainResultItemId: null
        },
        predefinedDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
          ttl: null,
          description: null
        },
        updateItem: {},
        update_text: "",
        deleteDialog: {
          isShow: false,
          message: '삭제 하시겠습니까?',
          click: null,
          deleteItem: null,
        },
        alertDialog: {
          isShow: false,
          title: '알림',
          message: '',
          icon: '',
        },
        confirmDialog: {
          isShow: false,
          title: '알림',
          message: '',
          icon: '',
          confirm: false,
        },
        progressDialog: {
          isShow: false,
          title: ''
        },
        predefined_set: {} ,
        vCorpus_id: [],
        vIntent: '',
        trainBtnDisabled: false,
        chart_title1: '',
        chart_option1: {},
        chart_option2: {},
        chart_option3: {},
        chart_option4: {},
        chart_option5: {},
        chart_option6: {},

        chartOptions: {
          title: {
              text: 'Custom DataLabels',
              align: 'center',
              floating: true
          },
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          }
        },
        chart_series1: [{
          data: []
        }],
        chart_series2: [{
          data: []
        }],
        chart_series3: [{
          data: []
        }],
        chart_series4: [{
          data: []
        }],
        chart_series5: [{
          data: []
        }],
        chart_series6: [{
          data: []
        }],
        series: [{
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        upload_methods: [
          { type: 'delete', desc: '파일 등록(기존셋 삭제)' },
          { type: 'remain', desc: '파일 등록(기존셋 유지)' },
        ],
      }
    },
    computed: {
      botItem () {
        return {
          botid: localStorage.getItem("botid"),
          botname: localStorage.getItem("botname"),
          fullname: localStorage.getItem("fullname"),
        }
      },
      filteredTrainIntentItems() {
        return this.trainIntentItems.filter(intent => intent.title.toLowerCase().includes(this.intentName.toLowerCase()))
      },
      filteredValidIntentItems() {
        return this.validIntentItems.filter(intent => intent.title.toLowerCase().includes(this.intentName.toLowerCase()))
      },
      filteredTestIntentItems() {
        return this.testIntentItems.filter(intent => intent.title.toLowerCase().includes(this.intentName.toLowerCase()))
      },
      filteredRegressionIntentItems() {
        return this.regressionIntentItems.filter(intent => intent.title.toLowerCase().includes(this.intentName.toLowerCase()))
      },
    },
    methods: {
      updateBotItem(botItem) {
        console.log('>> update botItem')
        if (botItem) {
          localStorage.setItem("botid", botItem["botid"])
          localStorage.setItem("botname", botItem["botname"])
          localStorage.setItem("fullname", botItem["fullname"])
        }
      },
      initBotItem(botItem) {
        console.log('>> init botItem')
        localStorage.removeItem('botid')
        localStorage.removeItem('botname')
        localStorage.removeItem('fullname')
      },
      updateDesSearch(newVal) {
        this.desSearch = newVal
      },
      async botList () {
        let customer = this.$store.state.user.customer
        await this.$http.get(process.env.VUE_APP_BOT_COMBO, {
          params: {
            'customer': customer
          }
        }).then(res => {
          const data = res.data.data
          console.log(data)
          this.botItems = data

          // if (this.$store.state.bot.id != '') {
          //   this.BotSelected = this.$store.state.bot.id
          //   console.log("BotSelected : " , this.BotSelected)
          //   this.botChange(true)
          // }

        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },
      confirmOK (flag) {
        this.confirmDialog['confirm'] = true
        this.confirmDialog['isShow'] = false
      },
      confirmNO () {
        this.confirmData = false
        this.confirmDialog['confirm'] = false
        this.confirmDialog['isShow'] = false
      },

      changeIntentSort() {
        this.intentSortByRegTime = !this.intentSortByRegTime
        this.params['type'] = this.typeName
        this.desSearch = ''
        this.getIntentList()
      },

      changeRoute (type) {
        console.log('changeRoute :', type)
        this.intentName = ''
        this.intentSortByRegTime = true
        this.botList()
        this.typeName = type
        if (type == 'reject') {
          this.params['type'] = type
          console.log(this.reject_check_list)
          this.getRejectIntentList()

        }else if (type == 'train' || type == 'test' || type == 'valid' || type == 'regression') {
          console.log(type)
          this.params['type'] = type
          this.desSearch = ''
          this.getIntentList()
        } else {
          // stat 통계
          this.params['type'] = type
          this.StatProcess()
        }
      },
      StatProcess() {
        if (this.params['botid'] != '' && this.params['customer'] != '') {
          this.progressDialog['isShow'] = true
          this.progressDialog['title'] = '잠시만 기다려 주세요.'
          this.getStat(this.params['customer'], this.params['botid'])
          this.getStat2(this.params['customer'], this.params['botid'])

          this.progressDialog['isShow'] = false

        }else{
          // 필수 아규먼츠가 없을때 대입
          this.params['customer'] = this.$store.state.user.customer;
        }
      },
      getStat (customer, botid) {
        this.$http.get(process.env.VUE_APP_STAT, {
          params: {
            'customer': customer,
            'botid': botid,
          },
        }).then(res => {
          const data = res.data.data

          this.chart_option1 = res.data.trainOptions
          this.chart_series1[0]['data'] = res.data.data['train']['series_per']

          this.chart_option2 = res.data.testOptions
          this.chart_series2[0]['data'] = res.data.data['test']['series_per']

          this.chart_option3 = res.data.validOptions
          this.chart_series3[0]['data'] = res.data.data['valid']['series_per']
          


        })


      },
      getStat2 (customer, botid) {
        this.$http.get(process.env.VUE_APP_STAT_WORD, {
          params: {
            'customer': customer,
            'botid': botid,
          },
        }).then(res => {
          const data = res.data.data
          this.chart_option4 = res.data.trainOptions
          this.chart_series4[0]['data'] = res.data.data['train']['series']

          this.chart_option5 = res.data.testOptions
          this.chart_series5[0]['data'] = res.data.data['test']['series']

          this.chart_option6 = res.data.validOptions
          this.chart_series6[0]['data'] = res.data.data['valid']['series']

        })
      },
      getIntentList () {
        // console.log('getIntentList this.params :', this.params)

        this.params['customer'] = this.$store.state.user.customer
        const botid = this.params['botid']
        this.$http.get(process.env.VUE_APP_TRAINER_INTENT_LIST, {
          params: {
            'customer': this.params['customer'],
            'type': this.params['type'],
            'botid': botid,
            'sortByTime': this.intentSortByRegTime,
          }
        }).then(res => {
          const data = res.data.data
          this.trainIntentItems = []
          this.testIntentItems = []
          this.validIntentItems = []
          this.regressionIntentItems = []

          if (this.params['type'] == 'train') {
            this.trainIntentItems = data
            // this.train_intent_active = data[0]['_id']
          } else if (this.params['type'] == 'test') {
            this.testIntentItems = data
          } else if (this.params['type'] == 'valid') {
            console.log(this.params['type'])
            this.validIntentItems = data
          } else if (this.params['type'] == 'regression') {
            this.regressionIntentItems = data
          }

          if (data.length > 0) {
            this.params['intent'] = data[0]['title']
            this.params['intent_id'] = data[0]['_id']
            this.train_active = 0
            this.test_active = 0
            this.valid_active = 0

            this.DataBind(data[0])

          } else {
            console.log('데이터 없음')
            // this.DataBind({'title': null, '_id': null})
            this.trainDataItems = []
            this.total = 0
            this.pageCount = 1
            this.progressDialog['isShow'] = false
          }
        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },
      async intentCreate () {
        if (this.intentCreating) {
          return
        }
        if (this.intentName == '') {
          // alert('인텐트명을 입력해 주세요.')
          this.alertDialog['isShow'] = true
          this.alertDialog['title'] = "알림"
          this.alertDialog['message'] = "인텐트명을 입력해 주세요."
          return
        }

        this.intentCreating = true
        const res = await this.$http.post(process.env.VUE_APP_TRAINER_INTENT_LIST, {
          'customer': this.params['customer'],
          'type': this.params['type'],
          'botid': this.params['botid'],
          'intent': this.intentName
        });

        if (res.data.success) {
          this.intentName = ""
          this.botChange(true)
        } else {
          let errmsg = res.data.errmsg
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = errmsg
        }
        this.intentCreating = false
      },
      async paging (value) {
        this.initPage = value

        this.params['start'] = this.initPage.page // (this.initPage.page -1) * this.initPage.itemsPerPage
        this.params['limit'] = this.initPage.itemsPerPage
        await this.getDataList()
      },
      async DataBind (intentItem) {
        // 리스트 선택
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '잠시만 기다려 주세요.'

        this.type_active = intentItem._id
        this.params['intent'] = intentItem.title
        this.params["intent_id"] = intentItem._id;
        this.initPage['page'] = 1
        this.initPage['itemsPerPage'] = 100


        // Example 삭제
        this.params['filter'] = ''
        this.desSearch = ''


        await this.paging(this.initPage)
      },
      async getDataList () {
        if (this.params['botid'] != '') {

          const { start , limit , ...args } = this.params;

          console.log('getDataList :', this.params)
          if (!this.progressDialog['isShow']) {
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '잠시만 기다려 주세요.'
          }
          await this.$http.get(process.env.VUE_APP_TRAINER_DATA_LIST, {
            params: this.params
          }).then(res => {
            if (res.data.success === true) {
              const total = res.data.total
              const data = res.data.data
              const pageCount = res.data.pageCount
              this.total = total
              this.pageCount = pageCount
              this.trainDataItems = data
            } else {
              this.trainDataItems = []
              this.pageCount = 1
              this.total = 0
            }
            this.progressDialog['isShow'] = false
          }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
          })
        }
      },
      async textSearch (text) {
        // console.log(text)
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '잠시만 기다려 주세요.'

        this.desSearch = text

        console.log(this.params)
        this.params['filter'] = text
        await this.getDataList()
      },
      textCreate (text) {
        //description 저장
        if (text == "") {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '발화를 입력해 주세요.'
          return
        }
        this.$http.post(process.env.VUE_APP_TRAINER_DATA_LIST, {
          'customer': this.params['customer'],
          'botid': this.params['botid'],
          'intent': this.params['intent'],
          'type': this.params['type'],
          'text': text

        }).then(res => {
          if (res.data.success === true) {
            // this.getDataList(this.selectIntent)
            // alert('저장되었습니다.');

            // this.alertDialog['isShow'] = true
            // this.alertDialog['message'] = '저장되었습니다.'

            this.initPage['page'] = 1
            this.initPage['itemsPerPage'] = 100
            this.paging(this.initPage)
          }else {
            const errmsg = res.data.errmsg
            // alert(errmsg)
            this.alertDialog['isShow'] = true
            this.alertDialog['title'] = '에러'
            this.alertDialog['message'] = errmsg
          }
        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },
      updateConfirm () {
        console.log(this.updateDialog['name'])
        if (this.updateDialog['name'] == 'Intent') {

          this.intentConfirm()
        } else {
          this.descConfirm()
        }
      },
      updatePredefined () {
        // this.predefined_set
        const intent = this.vIntent
        const botid = this.params['botid']



        if (this.vCorpus_id.length > 0) {
          this.predefined_set[botid][intent] = []
          this.vCorpus_id.forEach(d => {
            this.predefined_set[botid][intent].push(d)
          })
        } else {
          delete this.predefined_set[botid][intent]
        }

        this.predefinedDialog['isShow'] = false
        this.vCorpus_id = []

      },
      intentConfirm() {
        this.$http.put(process.env.VUE_APP_TRAINER_INTENT_DETAIL+this.updateItem['_id']+"/", {
          'intent': this.update_text,
          'customer': this.params['customer'],
          'type': this.params['type'],
          'botid': this.params['botid'],
          'before_intent': this.updateItem['title']
        }).then(res => {
          // alert('수정되었습니다.')
          // this.alertDialog['isShow'] = true
          // this.alertDialog['message'] = '수정되었습니다.'

          this.updateDialog['isShow'] = false
          this.getIntentList()
        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },
      descConfirm() {
        console.log('descConfirm')
        console.log(this.update_text)
        this.$http.put(process.env.VUE_APP_TRAINER_DATA_DETAIL+this.updateItem['_id']+"/", {
          'text': this.update_text
        }).then(res => {
          // this.alertDialog['isShow'] = true
          // this.alertDialog['message'] = '수정되었습니다.'
          this.updateDialog['isShow'] = false
          this.DataBind({'title': this.params['intent'], '_id': this.params['intent_id']})
        }).catch(error => {
          globalCatch(error)
          this.$router.push({ name: 'login'})
        })
      },
      editDescripion (item) {
        this.updateDialog['isShow'] = true
        this.updateDialog['name'] = 'Example'
        this.updateDialog['type'] = '수정'
        this.update_text = item.text
        this.updateItem = item
      },
      delDescription(item){
        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
           this.$http.delete(process.env.VUE_APP_TRAINER_DATA_DETAIL+item['_id']+"/", {
            }).then(res => {
              this.DataBind({'title': this.params['intent'], '_id': this.params['intent_id']})
            }).catch(error => {
              if (error.status == 403) {
                globalCatch(error)
                this.$router.push({ name: 'login'})
              }
            })
          },
          text : "해당 데이터를 삭제하시겠습니까?"
        })
      },
      addPredefined (item) {
        console.log(item)
        this.predefinedDialog['isShow'] = true
        this.predefinedDialog['name'] = '기구축학습셋'
        this.predefinedDialog['type'] = ''


        this.vIntent = item.title

        this.vCorpus_id = this.predefined_set[item.botid][item.title]
        console.log(this.vCorpus_id)

      },
      editIntent (item) {
        console.log(item)
        this.updateDialog['isShow'] = true
        this.updateDialog['name'] = 'Intent'
        this.updateDialog['type'] = '수정'
        this.update_text = item.title
        this.updateItem = item
      },
      deleteIntent (item) {
            this.$http.delete(process.env.VUE_APP_TRAINER_INTENT_DETAIL+item['_id']+"/", {
            }).then(res => {
              if (res.data.success) {
                delete this.predefined_set[item['title']]
                this.botChange(true);
                // this.getIntentList()
              }
            })
        // this.$store.dispatch("MODAL_ACTION",{
        //   isShow : true,
        //   callback : () => {
        //     this.$http.delete(process.env.VUE_APP_TRAINER_INTENT_DETAIL+item['_id']+"/", {
        //     }).then(res => {
        //       if (res.data.success) {
        //         // this.alertDialog['isShow'] = true
        //         // this.alertDialog['message'] = '삭제 되었습니다.'
        //         delete this.predefined_set[item['title']]
        //         this.botChange(true);
        //         // this.getIntentList()
        //       }
        //     })
        //   },
        //   text : "해당 인텐트를 삭제하시겠습니까?"
        // })


      },
      eachDownload(type) {
        const fileDownload = require('js-file-download')
        const botid = this.params['botid']

        this.$store.dispatch("MODAL_ACTION", {
          isShow: true,
          text: '다운로드 하시겠습니까?',
          callback: () => {
            this.progressDialog['isShow'] = true
            this.progressDialog['message'] = '파일 다운로드중입니다.'

            this.$http.get(process.env.VUE_APP_TRAINER_DOWNLOAD, {
              responseType: 'blob',
              params: this.params
            }).then(res => {
              const data = res.data
              let filename = this.params['botid'].concat("_", type, ".tsv")
              fileDownload(data, filename)
              this.progressDialog['isShow'] = false
            })
          }
        })
      },
      eachDelete (type) {
        const botid = this.params['botid']
        const customer = this.params['customer']

        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
            this.$http.post(process.env.VUE_APP_TRAINER_DELETE, {
              customer: customer,
              botid: botid,
              type: type
            }).then(res => {
              if (res.data.success) {

                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = '삭제 되었습니다.'
                // this.getIntentList()
                this.botChange(true);
                // this.botChange()
              } else {
                // 삭제 버튼
                this.alertDialog['isShow']  = true
                this.alertDialog['message'] = res.data.errmsg
              }
            }).catch(error => {
              if (error.status == 403) {
                globalCatch(error)
                this.$router.push({name: 'login'})
              }
            });
          },
          text : "해당 데이터를 삭제하시겠습니까?"
        })
      },
      Test () {
        alert("삭제?")
      },
      IntentDelete () {
        // intent 삭제
        console.log('test')
      },
      deleteOK() {
        const item = this.deleteDialog['deleteItem']
        // console.log('_id :', item['_id'])
        this.$http.delete(process.env.VUE_APP_TRAINER_DATA_DETAIL+item['_id']+"/", {}).then(res => {
        })

      },

      csv_upload (upload_method) {
        const formData = new FormData()
        if(this.files != null) {
          if (this.files['name'].indexOf(this.params['type']) != -1) {
            formData.append(this.params['type'], this.files)
            formData.append('customer', this.params['customer'])
            formData.append('botid', this.params['botid'])
            formData.append('upload_method', upload_method)
            // formData.append('filename', this.type)
          }else {
            //alert("*_"+this.params['type'] +".txt 파일 양식으로 올려주세요.")
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = "*_"+this.params['type'] +".txt 파일 양식으로 올려주세요."
            return
          }
          this.progressDialog['isShow'] = true
          this.progressDialog['title'] = '파일을 업로드 중입니다.'

          this.$http.post(process.env.VUE_APP_CSV_UPLOAD, formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-access-token': getCookie('x-access-token')
            }
          }).then(res => {
            const data = res.data
            this.progressDialog['isShow'] = false
            this.files = null
            if (data.success === true) {
              if (data.dup_data) {
                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = data.dup_data
              }
              this.changeRoute(this.params['type'])
            } else {
              this.alertDialog['isShow'] = true
              this.alertDialog['message'] = data.errmsg
            }

          }).catch(error => {
            const status = error.status
            const data = error.data
            this.progressDialog['isShow'] = false
            this.files = null
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = data.errmsg
          })
        } else {
          // alert("*_"+this.params['type'] +".txt 파일 양식으로 올려주세요.")
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = "*_"+this.params['type'] +".txt 파일 양식으로 올려주세요."
          return
        }
      },
      trainSenderOK() {

        if (this.senderDialog['test_count'] === 0) {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = "평가셋 데이터가 없습니다. 학습을 진행할수 없습니다."
          return;
        }


        this.trainBtnDisabled = true
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '학습데이터 처리중입니다. 잠시만 기다려주세요.'

        this.$http.post(process.env.VUE_APP_TRAINER_SENDER, {
          'customer': this.params['customer'],
          'botid': this.params['botid'],
          'predefined_set': this.predefined_set,
          'description': this.senderDialog['description_txt'] ,
          'method_for_valid': this.senderDialog['method_for_valid'],
          'model_type': this.senderDialog['model_type'],
          'outflip': this.senderDialog['outflip'],
        }).then(res => {
        this.progressDialog['isShow'] = false
          if (res.data.success) {
            this.intentName = ""
            let version = res.data.version
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = version + ' version 학습을 시작합니다.'
            this.senderDialog['isShow'] = false
            // this.$store.state.bot.id = this.params['botid']
          } else {
            let errmsg = res.data.errmsg
            alert(errmsg)
          }
        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }else {
            alert("관리자에게 문의하세요")
            this.progressDialog['isShow'] = false
          }
        })
      },
      trainSender() {
        // console.log(this.predefined_set)
        // const botid = this.params['botid']
        // this.predefined_set = this.$store.state.predefined_set[botid];
        this.trainBtnDisabled = false
        if (this.params['botid'] == '') {
            // alert('botid 를 선택해 주세요.')
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = 'botid 를 선택해 주세요.'
            return
        }

        this.$http.get(process.env.VUE_APP_SENDER_CHECK, {
          params: this.params
        }).then(res => {
          const data = res.data.data

          this.senderDialog['isShow'] = true
          this.senderDialog['description_txt']  = ""
          this.senderDialog['botid'] = this.params['botid']
          this.senderDialog['customer'] = this.params['customer']
          // this.senderDialog['predefined_set'] = ""
          this.senderDialog['predefined_set'] = JSON.stringify(this.predefined_set[this.params['botid']]) === '{}' ? "" : JSON.stringify(this.predefined_set[this.params['botid']])

          this.senderDialog['model_type'] = 'advanced(e)'

          this.senderDialog['train_count'] = data.train
          this.senderDialog['test_count'] = data.test
          this.senderDialog['valid_count'] = data.valid
        })

      },
      botChange ( isReloaded = false ) {
        // this.predefined_set = {}
        console.log("botChange")
        console.log(this.botItem)
        console.log(this.BotSelected)

        if (this.BotSelected == '' || this.BotSelected == undefined) {
          this.botStatus = false
          this.params['botid'] = ''
          this.params['botname'] = ''
        } else {
          this.botStatus = true
          let botItem = this.botItems.find(item => {return item['fullname'] === this.BotSelected})
          if (botItem === undefined) {
            this.initBotItem()
            return
          }
          this.updateBotItem(botItem)
          this.params['botid'] = botItem['botid']
          this.params['botname'] = botItem['botname']
          // this.$store.state.bot.id = botItem['botid']
          if (this.predefined_set[botItem['botid']] == undefined){
            this.predefined_set[botItem['botid']] = {}
          }

          // console.log('botChange :', this.params)
          if (this.params['type'] == 'stat') {
            this.StatProcess()
          } else {
            this.intentSortByRegTime = true
            this.getIntentList()
          }
        }
        if(!isReloaded){
          this.$router.push(`${this.$route.path}/${this.params["botid"]}`);
        }
      },
      // reject function start
      rejectBotChange() {
        if (this.BotSelected == '' || this.BotSelected == undefined) {
          this.botStatus = false
          this.params['botid'] = ''
          this.params['botname'] = ''
        } else {
          this.botStatus = true
          console.log(this.BotSelected)
          let botItem = this.botItems.find(item => {return item['fullname'] === this.BotSelected})
          this.params['botid'] = botItem['botid']
          this.params['botname'] = botItem['botname']

          this.getRejectIntentList()
        }
      },

      // Reject
      getRejectIntentList () {
        this.$http.get(process.env.VUE_APP_REJECT_INTENT_LIST, {
          params: {
          }
        }).then(res => {
          const data = res.data.data
          this.rejectIntentItems = data
          this.rejectIntentItems.forEach(d => {
            const corpus_id = d['corpus_id']
            this.reject_check_list.forEach(c => {

              if (d['corpus_id'] == c) {
                d['checked'] = true
              }
            })


          })
          console.log(this.rejectIntentItems)
          if (data.length > 0) {
            this.reject_active = 0
            this.getRejectDataBind(data[0])
          }


        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })

      },
      getRejectDataBind(intentItem) {
        this.reject_params['corpus_id'] = intentItem.corpus_id
        this.initPage['page'] = 1
        this.initPage['itemsPerPage'] = 100
        this.rejectPaging(this.initPage)
      },
      rejectPaging (value) {
        this.initPage = value
        this.reject_params['start'] = (this.initPage.page -1) * this.initPage.itemsPerPage
        this.reject_params['limit'] = this.initPage.itemsPerPage

        this.getRejectDataList()
      },
      getRejectDataList () {
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '잠시만 기다려 주세요.'
        this.$http.get(process.env.VUE_APP_REJECT_DATA_LIST, {
          params: this.reject_params
        }).then(res => {
          let success = res.data.success
          if (success) {
            const data = res.data.data
            const total = res.data.total
            this.rejectItems = data
            this.reject_total = total
            // console.log(this.rejectItems)
          } else {
            this.rejectItems = []
          }
        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
        this.progressDialog['isShow'] = false
      },
      intentChecked () {
        this.reject_check_list = []
        this.rejectIntentItems.forEach(d => {
          if (d['checked']) {
            console.log(d)
            this.reject_check_list.push(
              d['corpus_id']
            )
          }
        })
        const count = this.reject_check_list.length
        if (count == 0) {
          // alert('선택된 개수가 없습니다.')
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '선택된 개수가 없습니다.'
        }else {
          // alert(count + " 개 선택 적용 되었습니다.")
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = count + " 개 선택 적용 되었습니다."
        }
      },
      intentAllChecked() {
        console.log(this.intentAll)
        this.intentAll = !this.intentAll
        this.rejectIntentItems.forEach(d => {
          d['checked'] = this.intentAll
        })
      },
      movePage () {
      },
      // reject function end
      dataDelete () {
        const botid = this.params['botid']
        const customer = this.params['customer']
        if (botid == '') {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] ='BOTID 를 선택해 주세요.'

        } else {
          this.$store.dispatch("MODAL_ACTION",{
            isShow : true,
            callback : () => {
              this.$http.post(process.env.VUE_APP_TRAINER_DELETE, {
                customer: customer,
                botid: botid,
              }).then(res => {
                if (res.data.success) {

                  this.alertDialog['isShow'] = true
                  this.alertDialog['message'] ='삭제되었습니다.'
                  this.botChange(true);
                }
              }).catch(error => {
                if (error.status == 403) {
                  globalCatch(error)
                  this.$router.push({ name: 'login'})
                }
              })
            },
            text : "학습 / 평가 데이터를 전체 삭제하시겠습니까?"
          })
        }
      },

      modalTest () {
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '파일을 업로드 중입니다.'
      },
      predefinedList () {
        // 기구축학습셋 리스트
        this.$http.get(process.env.VUE_APP_REJECT_INTENT_LIST, {
        }).then(res => {
          const data = res.data.data
          this.predefinedItems = data
        })
      },

      // 학습결과 버전
      showRegressionPopup () {
        this.trainBtnDisabled = false
        if (this.params['botid'] == '') {
            // alert('botid 를 선택해 주세요.')
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = 'botid 를 선택해 주세요.'
            return
        }
        
        const customer = this.params['customer'];
        const botId = this.params['botid'];

        this.progressDialog['isShow'] = true
        this.$http.get(process.env.VUE_APP_TASKS_LIST, {
          params: {
            'botid': botId,
            'customer': customer,
            'appid': this.$store.state.user.appid
          }
        }).then(res => {
          this.progressDialog['isShow'] = false
          if (res.data.success == true) {
            this.regressionDialog['isShow'] = true
            this.regressionDialog['trainResultItemId'] = null

            const data = res.data.data
            const auth = res.data.auth
            const finished = res.data.finished

            this.trainResultItems = data
            this.trainResultItems.forEach(d => {
              d['accuracy'] = d['accuracy'].toFixed(2)
              d['btn_deployed'] = auth === 0 ? true : false
            })

            // this.series = this.taskItems['series']

            if (!finished) {
              this.timeid = setTimeout(() => {
                console.log(this.timeid)
                this.$store.state.timerId = this.timeid
                if (this.time_flag == 1) {
                  this.refresh()
                }
              }, 30000);
            } else {
              clearTimeout(this.timeid)
            }
          }
        }).catch(error => {
          this.progressDialog['isShow'] = false
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },

      regressionTest () {
        const selectedItem = this.trainResultItems.find(item => item['version'] === this.regressionDialog['trainResultItemId'])
        if (this.regressionDialog['trainResultItemId'] === null || selectedItem === undefined) {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '테스트를 진행할 학습결과 버전을 선택해주세요.'
          return
        }
        this.regressionDialog['isShow'] = false

        const customer = this.params['customer'];
        const botId = this.params['botid'];

        this.progressDialog['isShow'] = true
        this.$http.post(process.env.VUE_APP_REGRESSION, {
          'botid': botId,
          'customer': customer,
          'requestid': selectedItem.requestid,
          'version': selectedItem.version
        }).then(res => {
          this.progressDialog['isShow'] = false
          console.log(res)
        }).catch(error => {
          this.progressDialog['isShow'] = false
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
        
      }
    },
    // FIXME 이건 실행되나?
    watch:{
      botSelect : function (val) {
        if (val == '' || val == undefined){
          this.botStatus = false
          this.params['botid'] = ''
          this.params['botname'] = ''
        } else {
          this.botStatus = true
          this.params['customer'] = this.$store.state.user.customer

          let botItem = this.botItems.find(item => {return item['botname'] === val})
          this.params['botid'] = botItem['botid']
          this.params['botname'] = botItem['botname']

          this.getIntentList()
        }
      }
    },
  }
</script>

<style>
.intent-name {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main_wrapper {
  height: 732px;
}

.splitpanes__splitter {background-color: #ccc;position: relative;}
.splitpanes__splitter:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;
  background-color: rgba(255, 0, 0, 0.3);
  opacity: 0;
  z-index: 100;
}
.splitpanes__splitter:hover:before {opacity: 1;}
.splitpanes--vertical > .splitpanes__splitter:before {left: -5px;right: -5px;height: 100%;}
</style>
