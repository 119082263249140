<template>
  <v-dialog persistent v-model="showDialog" width="500">
    <v-card>
      <v-card-title class="headline">
        <span>테스트 결과 다운로드 설정</span>
      </v-card-title>
      <v-card-text class="text-xs-left subheading">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-subheader>
                검증 API 호출 횟수 제한
              </v-subheader>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="maxApiCallCount"
                :rules="maxApiCallCountRule"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">
          저장
        </v-btn>
        <v-btn color="primary" @click="close">
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    showDialog: Boolean,
    verificationMaxCount: Number,
  },
  components: {},
  data() {
    return {
      maxApiCallCount: '0',
      maxApiCallCountRule: [
        v => !!v || "필수 입력 항목입니다.",
        v => !(v && isNaN(v)) || "숫자를 입력하세요.",
        v => !(v && (v < 0 || v > 1000)) || "0이상, 1000이하의 값을 입력하세요.",
      ],
    }
  },
  watch: {
    showDialog(visible) {
      if (visible) {
        this.maxApiCallCount = this.verificationMaxCount.toString()
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      if(this.maxApiCallCount && !isNaN(this.maxApiCallCount) && this.maxApiCallCount >= 0 && this.maxApiCallCount <= 1000){
        this.$emit('save', this.maxApiCallCount)
      }
    },
  },
}
</script>
<style></style>
