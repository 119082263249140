<template>
<div>
  <v-data-table
    :headers="headers"
    :items="rejectItems"
    :desSearch="desSearch"
    style="padding-left:300px;"
    item-key="ids"
    class="elevation-1"
    height="600"
  >
    <template v-slot:top>
      <v-toolbar color="white">
          <v-col md="11">
          </v-col>
          <v-col md="1">
          <v-btn color="#D9CDBF" @click="textSearch()">적용 학습셋</v-btn>
          </v-col>
      </v-toolbar>
    </template>
    <template slot="items" slot-scope="props">
      <tr>
        <td class="text-xs-center">{{ props.item.text }}</td>
      </tr>
    </template>
  </v-data-table>

</div>
</template>
<script>

  export default {
    data () {
      return {
        desAdd : '',
        desSearch: '', 
        page: 1, 
        headers: [
          { text: 'Text', value: 'text', width: "100%" },
        ],
        desserts: [],
        pagination: {

        },
        footerProps : { 
          'items-per-page-text': 'Showing items:',
          'items-per-page-all-text': 'All of it'
        },
      }
    },
    props: {
      total: Number,
      // desSearch: String, 
      // pagination: {}, 
      rejectItems: Array, 
      selectIntent: String, 
    }, 
    watch: {
      pagination (value) {

        value.change = false 
        this.$emit('rejectPaging', value)
      }
    }, 
    methods: {
      textCreate() {
        if (this.desAdd != "") {
          this.$emit('textCreate', this.desAdd)
        }
      }, 
      textSearch() {
        this.$emit('textSearch', this.desSearch)
      }, 
   

    },
    computed : {
      pages () {
        if (this.rowsPerPage == null || this.trainDataItems == null) {
          return 0
        } else {
          return Math.ceil(this.total / this.rowsPerPage)
        }
      },
      totalCount () {
        return this.total
      }
    },
  }
</script>