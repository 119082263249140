<template>
  <v-container fluid v-if="iFilter === 0">
    <progress-dialog
      :dialog="progressDialog['isShow']"
      :title="progressDialog['title']"
    ></progress-dialog>
    <alert-dialog
      :dialog="alertDialog['isShow']"
      :title="alertDialog['title']"
      :message="alertDialog['message']"
      :icon="alertDialog['icon']"
      @close="alertDialog['isShow'] = false"
    ></alert-dialog>
    <common-dialog
      :title="'알림'"
      :icon="'info'"
      :message="commonDialog['message']"
      :dialog="commonDialog['isShow']"
      @close="commonDialog['isShow'] = false"
    ></common-dialog>

    <v-data-iterator
      :items="taskItems"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="search"
      :sort-desc="sortDesc"
      hide-default-footer

    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="#59493B"
          class="mb-1"
        >
          <v-col md="4">
            <v-select
              v-model="ModelBotSelected"
              hide-details
              dense
              item-text="fullname"
              item-value="botid"
              :items="botItems"
              v-on:change="botChange()"
              clearable
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:default="props" >
        <v-row v-show="botStatus == true">
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card
              min-height="100%"
            >
              <v-card-title class="subheading font-weight-bold">
                <div>
                  학습 버전: {{ item.version }}
                </div>
              </v-card-title>
              <v-card-title sm=1>
                {{ item.description }}
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>정확도:</v-list-item-content>
                  <v-list-item-content class="align-end" >{{ item.accuracy }} %</v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.outflip_result != 0">
                  <vue-good-list
                    :columns="outFlipHeaders"
                    :rows="item.outflipRows"
                  >
                  </vue-good-list>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>학습시작:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.start_time }}</v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>학습종료:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.end_time }}</v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>완료예정시간:</v-list-item-content>
                  <v-list-item-content class="align-end">{{ item.estimated_end_time }}</v-list-item-content>
                </v-list-item>


                <v-list-item>
                  <v-list-item-content>상태:</v-list-item-content>
                  <v-list-item-content>
                    <v-btn
                      color="red"
                      v-bind:class="btnColor(item.status)"
                      dark
                      @click="finishClick(item)"
                    >
                      {{ item.status === 'finish' ? "학습 결과 확인" : item.status }}
                      <v-icon dark right></v-icon>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>진행</v-list-item-content>
                  <v-progress-linear
                    class="mb-0"
                    color="blue-grey"
                    height="25"
                    dark
                    :value="Math.ceil(item.progress)"
                    disabled
                  >
                    <template>
                      <strong>{{ Math.ceil(item.progress) }}%</strong>
                    </template>
                  </v-progress-linear>
                </v-list-item>
                <v-list-item v-if="item.graph == true">
                  <v-list-item-content>
                    <apexchart
                      type="line"
                      height="350"
                      :options="item.chart_options"
                      :series="item.series"
                    ></apexchart>
                  </v-list-item-content>
                </v-list-item>
                <div class="text-center">
                  <div>
                    <v-btn
                      depressed
                      large
                      class="ma-1 white--text"
                      color="blue darken-3"
                      @click="serviceBotDeploy(item.child_bot.botid, item)"
                      :disabled="item.btn_deployed"
                      v-if="item.child_bot.model_version != item.version"
                      >
                        <span>서비스봇배포</span>
                        <v-icon right >mdi-cloud-upload</v-icon>
                    </v-btn>

                    <v-btn
                      depressed
                      large
                      class="ma-1 white--text"
                      :dark="!item.btn_deployed"
                      :disabled="item.btn_deployed"
                      v-if="item.child_bot.model_version==item.version && item.child_bot.deploy==true"
                      >
                      <span>배포완료</span>
                      <v-icon right>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn
                      depressed
                      large
                      class="ma-1 white--text"
                      color="blue-grey lighten-1"
                      :dark="!item.btn_deployed"
                      :disabled="item.btn_deployed"
                      v-if="item.deployed == false"
                      @click="modelDeploy(item)"
                    >
                      <span>개발봇 배포</span>
                      <v-icon right>mdi-cloud-upload</v-icon>
                    </v-btn>
                    <v-btn
                        class="ma-1 white--text"
                        large
                        depressed
                        color="brown darken-3"
                        :disabled="item.btn_deployed"
                      v-else>
                      <span>개발봇 배포완료</span>
                      <v-icon right>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn
                      depressed
                      large
                      :disabled="item.btn_deployed"
                      class="ma-1 white--text"
                      color="blue-grey lighten-1"
                      @click="modelDelete(item)"
                    >
                      <span>학습삭제</span>
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center" v-show="taskItems.length > 0">
          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <span
            class="mr-4
            grey--text"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="blue darken-3"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="blue darken-3"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>

    </v-data-iterator>

  </v-container>

  <v-container fluid v-else-if="iFilter === 1">
    <v-card>
      <v-toolbar
        dark
        color="#59493B"
        class="mb-1"
      >
      <v-layout row wrap pa-4>
        <v-col md="4">
          <v-select
            v-model="ModelBotSelected"
            hide-details
            dense
            item-text="fullname"
            item-value="botid"
            :items="botItems"
            v-on:change="botChange()"
            clearable
            disabled
          ></v-select>
        </v-col>
          <v-col md="2">
            <v-select
              v-model="versionSelected"
              hide-details
              dense
              item-text="version_txt"
              item-value="requestid"
              :items="taskItems"
              v-on:change="versionChange($event)"
              clearable
            ></v-select>
          </v-col>
        <v-col md="4">
          <v-btn color="#D9CDBF" @click="moveModel()">학습결과 관리 이동</v-btn>
          <v-btn class="ml-2" color="#D9CDBF" @click="moveRegression()">Regression Test 결과 보기</v-btn>
        </v-col>

          <v-spacer></v-spacer>

        </v-layout>
      </v-toolbar>

      <!-- intent & table 바인딩 -->
      <v-main class="pt-0 pb-0">
        <v-navigation-drawer
          :right="false"
          absolute
          :width="480"
          overflow
        >
          <v-list
            dense
            nav
            class="py-0"
          >
            <v-list-item-group
              v-model="train_active"
              mandatory color="brown darken-2"
            >
            <v-list-item
              v-for="item in intentItems"
              :key="item._id"
              link
            >
              <v-list-item-content class="pt-0 pb-0">
                <v-list-item-title
                  class="pt-3"
                  style="height:38px;"
                  role="button"
                  @click="DataBind(item)"
                >
                  {{ item.title }}
                </v-list-item-title>

              </v-list-item-content>

              <div>
                <v-chip
                  class="ma-2"
                  color="red"
                  v-text="item.ebot_threshold"
                  v-if="item.title !== '[ALL]'"
                ></v-chip>
                <v-chip
                  class="ma-2"
                  v-text="item.pbot_threshold"
                  v-if="item.title !== '[ALL]'"
                ></v-chip>
              </div>
              <div v-if="correct_type == false" @click="DataBind(item)" style='width:160px; text-align:right;'>
                {{ item.correct_true }} / {{ item.count }}  ({{ item.true_percent }})
              </div>
              <div v-else @click="DataBind(item)">
                {{ item.count }}
              </div>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <model-log-list
          :intentId="params['intent']"
          :snapshotItems="snapshotItems"
          :total="total"
          :desSearch="desSearch"
          :correctSelected="correctSelected"
          :pageCount="pageCount"
          :firstIntentSortSelected="firstIntentSortSelected"
          :selectedUtterance="selectedUtterance"

          @paging="paging"
          @textSearch="textSearch"
          @updateSelectedUtterance="updateSelectedUtterance"
          @moveToTrain="moveToTrain"

          @excelDown="excelDown"
          @showTestDownloadSettingDialog="showTestDownloadSettingDialog"
          @confusionMatrixCall="confusionMatrixCall"
          @threshold_setting="threshold_setting"
          @rule_setting="rule_setting"

        >
        </model-log-list>
      </v-main>
      <progress-dialog
        :dialog="progressDialog['isShow']"
        :title="progressDialog['title']"
      ></progress-dialog>
      <test-download-setting-dialog
        :showDialog="testDownloadSettingDialog['isShow']"
        :verificationMaxCount="testDownloadSettingDialog['verificationMaxCount']"
        @close="testDownloadSettingDialog['isShow'] = false"
        @save="saveTestDownloadSetting"
      ></test-download-setting-dialog>
      <confusion-data-dialog
        :dialog="confusionDataDialog['isShow']"
        :headers="headers"
        :dataSource="dataSource"

        transition="dialog-bottom-transition"
        @close="confusionDataDialog['isShow'] = false"
      ></confusion-data-dialog>
      <threshold-setting-dialog
        :dialog="thresholdDialog['isShow']"
        :threshold_rows="threshold_rows"
        @threshold_save="threshold_save"
        @close="thresholdDialog['isShow'] = false"
      ></threshold-setting-dialog>
      <alert-dialog
        :dialog="alertDialog['isShow']"
        :title="alertDialog['title']"
        :message="alertDialog['message']"
        :icon="alertDialog['icon']"
        @close="alertDialog['isShow'] = false"
      ></alert-dialog>
      <rule-tree-dialog
        :dialog="ruleTreeDialog['isShow']"
        :ruleItems="ruleItems"
      ></rule-tree-dialog>

    </v-card>
  </v-container>

  <v-container fluid v-else-if="iFilter === 2">
    <v-card>
      <v-toolbar
        dark
        color="#59493B"
        class="mb-1"
      >
      <v-layout row wrap pa-4>
        <v-col md="4">
          <v-select
            v-model="ModelBotSelected"
            hide-details
            dense
            item-text="fullname"
            item-value="botid"
            :items="botItems"
            v-on:change="botChange()"
            clearable
            disabled
          ></v-select>
        </v-col>
          <v-col md="2">
            <v-select
              v-model="versionSelected"
              hide-details
              dense
              item-text="version_txt"
              item-value="requestid"
              :items="taskItems"
              v-on:change="versionChange($event)"
              clearable
            ></v-select>
          </v-col>
        <v-col md="4">
          <v-btn color="#D9CDBF" @click="moveModel()">학습결과 관리 이동</v-btn>
          <v-btn class="ml-2" color="#D9CDBF" @click="moveTrainResult()">학습결과 보기</v-btn>
        </v-col>

          <v-spacer></v-spacer>

        </v-layout>
      </v-toolbar>

      <!-- intent & table 바인딩 -->
      <v-main class="pt-0 pb-0">
        <v-navigation-drawer
          :right="false"
          absolute
          :width="480"
          overflow
        >
          <v-list
            dense
            nav
            class="py-0"
          >
            <v-list-item-group
              v-model="train_active"
              mandatory color="brown darken-2"
            >
            <v-list-item
              v-for="item in intentItems"
              :key="item._id"
              link
            >
              <v-list-item-content class="pt-0 pb-0">
                <v-list-item-title
                  class="pt-3"
                  style="height:38px;"
                  role="button"
                  @click="DataBind(item)"
                >
                  {{ item.title }}
                </v-list-item-title>

              </v-list-item-content>

              <div>
                <v-chip
                  class="ma-2"
                  color="red"
                  v-text="item.ebot_threshold"
                  v-if="item.title !== '[ALL]'"
                ></v-chip>
                <v-chip
                  class="ma-2"
                  v-text="item.pbot_threshold"
                  v-if="item.title !== '[ALL]'"
                ></v-chip>
              </div>
              <div v-if="correct_type == false" @click="DataBind(item)" style='width:160px; text-align:right;'>
                {{ item.correct_true }} / {{ item.count }}  ({{ item.true_percent }})
              </div>
              <div v-else @click="DataBind(item)">
                {{ item.count }}
              </div>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <model-log-list
          :intentId="params['intent']"
          :snapshotItems="snapshotItems"
          :total="total"
          :desSearch="desSearch"
          :correctSelected="correctSelected"
          :pageCount="pageCount"
          :firstIntentSortSelected="firstIntentSortSelected"
          :selectedUtterance="selectedUtterance"

          @paging="paging"
          @textSearch="textSearch"
          @updateSelectedUtterance="updateSelectedUtterance"
          @moveToTrain="moveToTrain"

          @excelDown="excelDown"
          @showTestDownloadSettingDialog="showTestDownloadSettingDialog"
          @confusionMatrixCall="confusionMatrixCall"
          @threshold_setting="threshold_setting"
          @rule_setting="rule_setting"

        >
        </model-log-list>
      </v-main>
      <progress-dialog
        :dialog="progressDialog['isShow']"
        :title="progressDialog['title']"
      ></progress-dialog>
      <test-download-setting-dialog
        :showDialog="testDownloadSettingDialog['isShow']"
        :verificationMaxCount="testDownloadSettingDialog['verificationMaxCount']"
        @close="testDownloadSettingDialog['isShow'] = false"
        @save="saveTestDownloadSetting"
      ></test-download-setting-dialog>
      <confusion-data-dialog
        :dialog="confusionDataDialog['isShow']"
        :headers="headers"
        :dataSource="dataSource"

        transition="dialog-bottom-transition"
        @close="confusionDataDialog['isShow'] = false"
      ></confusion-data-dialog>
      <threshold-setting-dialog
        :dialog="thresholdDialog['isShow']"
        :threshold_rows="threshold_rows"
        @threshold_save="threshold_save"
        @close="thresholdDialog['isShow'] = false"
      ></threshold-setting-dialog>
      <alert-dialog
        :dialog="alertDialog['isShow']"
        :title="alertDialog['title']"
        :message="alertDialog['message']"
        :icon="alertDialog['icon']"
        @close="alertDialog['isShow'] = false"
      ></alert-dialog>
      <rule-tree-dialog
        :dialog="ruleTreeDialog['isShow']"
        :ruleItems="ruleItems"
      ></rule-tree-dialog>

    </v-card>
  </v-container>
</template>

<script>
import ModelLogList from '@/components/ModelLog/ModelLogList.vue'
import CommonDialog from '@/components/common/CommonDialog.vue'
import ProgressDialog from '@/components/common/ProgressDialog.vue'
import TestDownloadSettingDialog from '@/components/common/TestDownloadSettingDialog.vue'
import ConfusionDataDialog from '@/components/common/ConfusionDataDialog.vue'
import ThresholdSettingDialog from '@/components/common/ThresholdSettingDialog.vue'
import { decodeToken, getCookie, deleteCookie, globalCatch} from '@/utils'
import AlertDialog from '@/components/common/AlertDialog.vue'
// import RuleTreeDialog from  '@/components/common/RuleTreeDialog.vue'
import  VueGoodList from '@/components/grid/VueGoodList.vue'
import { mapActions } from "vuex";

  export default {
    name : "ModelLogContainer",
    props : ["botId"],
    components: {
      AlertDialog,
      ModelLogList,
      CommonDialog,
      ProgressDialog,
      TestDownloadSettingDialog,
      ConfusionDataDialog,
      ThresholdSettingDialog,
      VueGoodList
      // RuleTreeDialog
    },
    data () {
      return {
        selectedUtterance: [],
        ruleItems: [],
        threshold_rows: [],
        botStatus : false,
        desSearch: '',
        correctSelected: 0,
        firstIntentSortSelected: 1,
        train_active: 0,
        confusion_data: '',
        total: 0,
        intentItems: [],
        versionSelected: '',
        intentName: '',
        keys: [],
        tab: true,
        botItems: [],
        skill: 20,
        iFilter: 0,
        itemsPerPageArray: [40, 80, 120],
        activeBtn: 1,
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 40,
        sortBy: 'name',
        ModelBotSelected: '',
        reqSelected: '',
        reqSelectedVersion: '',
        timeid: null,
        time_flag: 1,
        taskItems: [
        ],
        commonDialog: {
          isShow: false,
          title: '',
          message: '',
        },
        snapshotItems: [],
        pageCount: 1,
        params: {
          // customer: 'dkt',
          botid: '',
          botname: '',
          text: null,
          search_text: null,
          search_correct: null,
        },
        initPage : {
          page: 1,
          itemsPerPage: 10,
          change: false,
        },
        correct_type: false,
        progressDialog: {
          isShow: false,
          title: ''
        },
        testDownloadSettingDialog: {
          isShow: false,
          verificationMaxCount: 0,
        },
        confusionDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
        },
        thresholdDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
        },
        confusionDataDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null,
        },
        ruleTreeDialog: {
          isShow: false,
          name: null,
          type: null,
          icon: null
        },
        alertDialog: {
          isShow: false,
          title: '알림',
          message: '',
          icon: '',
        },
        xAxis: {
          labels: [],
        },
        yAxis: {
          // visible: false,
          // labelIntersectAction: 'None',
          // isInversed:false,
          labels: [],
        },
        dataSource: [
        ],

        yLabels: [],
        xLabels: [],
        cellSettings: {
          showLabel: true
        },
        outFlipHeaders:[
          {"field": "name1", 'label': '', 'width': '30%','tdClass': 'text-class'},
          {"field": "name2", 'label': 'OutFlip 적용전', 'width': '35%','tdClass': 'text-class'},
          {"field": "name3", 'label': 'OutFlip 적용후', 'width': '35%', 'tdClass': 'text-class'}
        ],
        // outFlipRows: [
        //   {'name1': 'In Domain', 'name2': 'A', 'name3': 'B'},
        //   {'name1': 'Out of Domain', 'name2': 'C', 'name3': 'D'}
        // ],
        headers: [
        ],
        paletteSettings: {
            palette: [
                { value: 0, color: '#FFFFFF' },
                { value: 50, color: '#0099FF' },
                { value: 100, color: '#003366' }
                ],
            type: "Gradient"
        }      ,
        cnt: 0 ,
        cnt_sum: 0,
        // series: [{
        //     name: "Desktops",
        //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        // }],
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: '',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00'],
          }
        },
      }
    },
    created () {
      console.log("store start")
      console.log(this.$store.state.predefined_set)
      console.log(this.botItem)
      console.log("store end");

      ( async () => {
        this.refresh_count = 0
        await this.botList();
        if (this.botItem.fullname){
          // 봇 선택 한채로 새로고침 시 해당 아규먼츠 기준대로 데이터를 재 로딩시킨다.
          this.ModelBotSelected = this.botItem.botid;
          this.botChange(true);
        }
      })()
    },
    beforeRouteUpdate (to , from , next){
      const { params } = to;
      if ( this.botItem.botid !== undefined) {
        this.ModelBotSelected = this.botItem.botid
      } else {
        this.ModelBotSelected = ''
      }
      if(!params.botId){
        // 라우터 쿼리가 변경됨에 따라 재 메뉴 진입으로 하기 봇상태 설정을 변경
        this.botStatus = false;
        this.iFilter = 0;
        this.taskItems = [];
        this.page = 1;
        if (this.botItem.botid !== undefined) {
          this.ModelBotSelected = this.botItem.botid
        } else {
          this.ModelBotSelected = ''
        }

      }
      next();
    },
    computed: {
      imageSrc() {
          return this.myPath;
      },
      numberOfPages () {
        return Math.ceil(this.taskItems.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== `Name`)
      },
      botItem () {
        return {
          botid: localStorage.getItem("botid"),
          botname: localStorage.getItem("botname"),
          fullname: localStorage.getItem("fullname"),
        }
      },
    },
    methods: {
      ...mapActions({
        startDownload: 'startDownload',
      }),
      updateBotItem(botItem) {
        console.log('>> update botItem')
        console.log(botItem)
        if (botItem) {
          localStorage.setItem("botid", botItem["botid"])
          localStorage.setItem("botname", botItem["botname"])
          localStorage.setItem("fullname", botItem["fullname"])
        }
      },
      initBotItem(botItem) {
        console.log('>> init botItem')
        console.log(botItem)
        localStorage.removeItem('botid')
        localStorage.removeItem('botname')
        localStorage.removeItem('fullname')
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      btnColor (state) {
        if (state == 'finish') return 'primary'
        else if (state == 'error') return 'red'
        else if (state == 'init') return 'green'
        else return 'red--black'
      },
      stateColor (state) {
        if (state == 'finish') return 'primary--text'
        else if (state == 'error') return 'red--text'
        else if (state == 'init') return 'green--text'
        else return 'red--black'
      },
      async botList () {
        let customer = this.$store.state.user.customer
        console.log(customer)
        console.log(this.$store.state.user.appid)
        await this.$http.get(process.env.VUE_APP_BOT_COMBO, {
          params: {
            'customer': customer
          }
        }).then(res => {
          const data = res.data.data
          console.log(data)
          this.botItems = data


          this.botChange()

        }).catch(error => {
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
      },
      versionChange (payload) {
        const taskItem = this.taskItems.find(task => task.requestid === payload)
        if (taskItem) this.reqSelectedVersion = taskItem.version_txt
        this.reqSelected = this.versionSelected
        this.params['search_text'] = null
        this.params['search_text'] = null
        this.IntentList()
      },
      botChange( isReload = false ) {
        console.log("botChange")
        console.log(this.ModelBotSelected)

        if (this.ModelBotSelected === '') {
          return
        }
        // 현재 선택한 봇 저장
        let botItem = this.botItems.find(item => {return item['botid'] === this.ModelBotSelected})
        if (botItem === undefined) {
          this.initBotItem()
          return
        }
        this.updateBotItem(botItem)

        let appid = this.$store.state.user.appid
        let customer = this.$store.state.user.customer
        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '잠시만 기다려 주세요.'
        this.$http.get(process.env.VUE_APP_TASKS_LIST, {
          params: {
            'botid': this.ModelBotSelected,
            'customer': customer,
            'appid': appid
          }
        }).then(res => {
          this.progressDialog['isShow'] = false
          if (res.data.success == true) {
            const data = res.data.data
            const auth = res.data.auth
            const finished = res.data.finished

            this.taskItems = data
            this.taskItems.forEach(d => {
              d['accuracy'] = d['accuracy'].toFixed(2)
              d['btn_deployed'] = auth === 0 ? true : false
            })

            // this.series = this.taskItems['series']

            if (!finished) {
              this.timeid = setTimeout(() => {
                console.log(this.timeid)
                this.$store.state.timerId = this.timeid
                if (this.time_flag == 1) {
                  this.refresh()
                }
              }, 30000);
            } else {
              clearTimeout(this.timeid)
            }
          }
        }).catch(error => {
          this.progressDialog['isShow'] = false
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
        // if( !isReload ){
        //   console.log(isReload)
        //   this.$router.push(`/ModelLog/${this.ModelBotSelected}`);
        // }
        this.botStatus = true;
      },
      StatusModal (item) {
        console.log(item)
      },
      finishClick (item) {
        this.time_flag = 0
        this.reqSelected = item.requestid
        this.reqSelectedVersion = item.version
        this.versionSelected = item.requestid

        // clearTimeout(this.timeid)
        clearInterval(this.timeid)
        this.IntentList()
      },
      IntentList () {
        this.iFilter = 1

        this.intentItems = []
        this.snapshotItems = []
        let requestid = this.reqSelected

        const intent_params = {
          'requestid': requestid,
          'search_text': this.params['search_text'],
          'search_correct': this.params['search_correct'],
        }
        
        this.$http.get(process.env.VUE_APP_INTENT_LIST, {
          // params: {'requestid': requestid}
          params: intent_params
        }).then( res => {
          this.snapshotItems = []
          if (res.data.success) {
            const data = res.data.data
            this.intentItems = data
            this.train_active = 0
            this.params['requestid'] = requestid
            this.correct_type = res.data.correct_type
            console.log(data[0])
            this.DataBind(data[0])
          }
        }).catch(error => {
          this.total = 0;
          this.pageCount = 0
          this.snapshotItems = []
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
        // 스냅샷 테이블 intent
      },
      RegressionIntentList () {
        this.iFilter = 2

        this.intentItems = []
        this.snapshotItems = []
        let requestid = this.reqSelected

        const intent_params = {
          'requestid': requestid,
          'search_text': this.params['search_text'],
          'search_correct': this.params['search_correct'],
          'type': 'regression'
        }
        
        this.$http.get(process.env.VUE_APP_INTENT_LIST, {
          // params: {'requestid': requestid}
          params: intent_params
        }).then( res => {
          this.snapshotItems = []
          if (res.data.success) {
            const data = res.data.data
            this.intentItems = data
            this.train_active = 0
            this.params['requestid'] = requestid
            this.correct_type = res.data.correct_type
            console.log(data[0])
            this.DataBind(data[0])
          }
        }).catch(error => {
          this.total = 0;
          this.pageCount = 0
          this.snapshotItems = []
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          }
        })
        // 스냅샷 테이블 intent
      },
      async paging (value) {
        if (!this.progressDialog['isShow']) {
          this.progressDialog['isShow'] = true
          this.progressDialog['title'] = '잠시만 기다려 주세요.'
        }
        this.selectedUtterance = []
        this.initPage = value
        this.params['start'] = (this.initPage.page -1) * this.initPage.itemsPerPage
        this.params['page'] = this.initPage.page
        this.params['limit'] = this.initPage.itemsPerPage
        this.params['firstIntentSort'] = this.initPage.firstIntentSort
        console.log('paging function')
        await this.getDataList()
      },
      async DataBind (intentItem) {
        // console.log('DataBind start')
        // console.log(intentItem['title'])
        // console.log(this.desSearch);
        this.desSearch = ""
        this.correctSelected = 0
        this.params['search_text'] = this.desSearch
        this.params['search_correct'] = this.correctSelected

        this.progressDialog['isShow'] = true
        this.progressDialog['title'] = '잠시만 기다려 주세요.'
        this.intentName = intentItem['title']
        // intentItem['title']

        this.initPage['page'] = 1
        this.initPage['itemsPerPage'] = 10
        this.params['intent'] = this.intentName

        // first intent sort init + set
        this.firstIntentSortSelected = 1
        this.initPage['firstIntentSort'] = this.firstIntentSortSelected

        await this.paging(this.initPage)
      },
      async getDataList () {
        let paramsTemp = this.params;

        await this.$http.get(process.env.VUE_APP_SNAPSHOT_DATA_LIST, {
          params: paramsTemp
        }).then(res => {
          if (res.data.success == true) {
            const total = res.data.total
            const data = res.data.data
            const pageCount = res.data.pageCount
            this.total = total
            this.snapshotItems = data
            this.pageCount = pageCount
            this.progressDialog['isShow'] = false
          } else {
            this.pageCount = 1
            this.snapshotItems = []
          }
        }).catch(error => {
          this.total = 0;
          this.pageCount = 1
          this.snapshotItems = []
          const status = error.status
          if (error.status == 403) {
            globalCatch(error)
            this.$router.push({ name: 'login'})
          } else {
            this.progressDialog['isShow'] = false
          }
        })
      },
      async textSearch (text, correct, page, pageSize, firstIntentSort) {
        // this.params['']
        // console.log(text, corect);
        this.params['search_text'] = text
        this.params['search_correct'] = correct
        this.desSearch = text
        this.correctSelected = correct
        this.firstIntentSortSelected = firstIntentSort
        // this.IntentList()

        if (page) {
          this.initPage['page'] = page
        } else {
          this.initPage['page'] = 1
        }
        if (pageSize) {
          this.initPage['itemsPerPage'] = pageSize
        } else {
          this.initPage['itemsPerPage'] = 10
        }
        if (firstIntentSort) {
          this.initPage['firstIntentSort'] = firstIntentSort
        } else {
          this.initPage['firstIntentSort'] = 1
        }
        this.params['intent'] = this.intentName
        await this.paging(this.initPage);
      },
      updateSelectedUtterance(value) {
        this.selectedUtterance = value
      },
      moveToTrain () {
        // 선택된 발화를 학습셋에 등록
        if (this.selectedUtterance.length === 0) {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '선택된 발화가 없습니다.'
        } else {
          console.log(">>> Selected Utterance")
          console.log(this.selectedUtterance)
          const customer = this.$store.state.user.customer
          this.$store.dispatch("MODAL_ACTION", {
            isShow: true,
            callback: () => {
              this.progressDialog['isShow'] = true
              this.progressDialog['title'] = '잠시만 기다려 주세요.'
              this.$http.post(process.env.VUE_APP_MODEL_MOVE_TO_TRAINER, {
                botid: this.ModelBotSelected,
                customer: customer,
                selected_utterance: this.selectedUtterance,
              }).then(res => {
                if (this.progressDialog['isShow'])
                  this.progressDialog['isShow'] = false
                let message = ''
                if (!res.data.success) {
                  message = res.data.errmsg
                } else {
                  const doneCnt = res.data.data.trainer_done_cnt
                  message = `발화등록 완료 ${doneCnt}개`
                  if (res.data.data.dup_data !== undefined){
                    const dupCnt = res.data.data.trainer_dup_cnt
                    message += `, 제외(중복 데이터) ${dupCnt}개`
                  }
                  this.selectedUtterance = []
                }
                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = message
              }).catch(error => {
                if (this.progressDialog['isShow']) this.progressDialog['isShow'] = false
                if (error.status == 403) {
                  globalCatch(error)
                  this.$router.push({name: 'login'})
                }
                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = '학습셋 발화등록이 실패하였습니다.'
              })
            },
            text: '선택된 ' + this.selectedUtterance.length + '개 발화를 학습셋에 등록하시겠습니까?'
          })
        }
      },
      moveModel () {
        this.iFilter = 0
        this.time_flag = 1
        this.refresh()
      },
      moveTrainResult () {
        this.iFilter = 1
        this.params['type'] = 'test';
        this.IntentList()
      },
      moveRegression () {
        this.iFilter = 2
        this.params['type'] = 'regression';
        this.RegressionIntentList()
      },
      modelDeploy (item) {

        if (item.status != 'finish') {

          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '해당 모델이 학습 진행중입니다. \n 학습 완료후 배포가능합니다.'
          return
        }

        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '잠시만 기다려 주세요.'
            this.$http.post(process.env.VUE_APP_MODEL_DEPLOY, {
              'req_id': item.requestid
            }).then(res => {
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              this.alertDialog['isShow'] = true
              this.alertDialog['message'] = '배포 되었습니다.'
              this.refresh()
            }).catch(error => {
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              if (error.status == 403) {
                globalCatch(error)
                this.$router.push({ name: 'login'})
              }
            })
          },
          text : "개발봇 배포를 진행 하시겠습니까?"
        })
        // console.log(item)
      },
      modelDelete(item) {
        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
            console.log(item)
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '잠시만 기다려 주세요.'
            this.$http.post(process.env.VUE_APP_MODEL_DEPLOY_DELETE, {
              req_id: item.requestid,
              botid: item.botid,
            }).then(res => {
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              this.alertDialog['isShow'] = true
              this.alertDialog['message'] = '삭제 되었습니다.'
              this.botChange()
            }).catch(error =>{
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              if (error.status == 403) {
                globalCatch(error)
                this.$router.push({ name: 'login'})
              }
              this.alertDialog['isShow'] = true
              this.alertDialog['message'] = '삭제 실패 하였습니다.'
            })
          },
          text : "모델을 삭제하시겠습니까?"
        })
      },
      refresh () {
        console.log('timeid : ' + this.timeid)
        this.botChange()
      },
      serviceBotDeploy (botid, item) {
        if (botid === undefined) {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = "서비스봇ID가 존재하지 않습니다."
          return
        }
        console.log("serviceBot NOT YET")
        this.$store.dispatch("MODAL_ACTION",{
          isShow : true,
          callback : () => {
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '잠시만 기다려 주세요.'
            this.$http.post(process.env.VUE_APP_SERVICE_BOT_DEPLOY, {
              'botid': botid,
              'requestid': item.requestid
            }).then(res => {
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              this.alertDialog['isShow'] = true
              this.alertDialog['message'] = '서비스봇이 배포되었습니다.'
              this.refresh()
            }).catch(error => {
              if (this.progressDialog['isShow'] === true) this.progressDialog['isShow'] = false
              if (error.status == 403) {
                globalCatch(error)
                this.$router.push({ name: 'login'})
              }
            })
          },
          text : "서비스봇 배포를 진행 하시겠습니까?"
        })
      },
      serviceBotDelete (child, item) {
        if (child.service == true) {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = '서비스 반영이 되어 삭제할수 없습니다.'
        }else {
          this.$store.dispatch("MODAL_ACTION",{
            isShow : true,
            callback : () => {
              this.$http.post(process.env.VUE_APP_SERVICE_BOT_DELETE, {
                'botid': child.botid,
                'requestid': item.requestid
              }).then(res => {
                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = '서비스봇이 삭제되었습니다.'
                this.refresh()
              }).catch(error => {
                if (error.status == 403) {
                  globalCatch(error)
                  this.$router.push({ name: 'login'})
                }
              })
            },
            text : "서비스봇 배포를 삭제 하시겠습니까?"
          })
        }
      },
      async excelDown (type, include_verification) {
        this.params['type'] = type
        this.params['version'] = this.reqSelectedVersion
        const botItem = this.botItems.find(item => item.botid === this.ModelBotSelected)
        if (botItem) this.params['fullname'] = botItem.fullname
        this.params['include_verification'] = include_verification
        this.params['customer'] = this.$store.state.user.customer
        await this.startDownload(this.params)
        const alertMsg = this.$store.state.alertMsg
        if (alertMsg !== '') {
          this.alertDialog['isShow'] = true
          this.alertDialog['message'] = alertMsg
        }
      },
      close() {
        this.confusionDialog['isShow'] = false
      },
      forceFileDownload(response){
        const url = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.png') //or any other extension
        document.body.appendChild(link)
        link.click()
      },
      tdClassFunc(row, index) {
        console.log('tdClassFunc :', row, index)


        return 'level1-class'


      },
      saveTestDownloadSetting(max_count) {
        this.$store.dispatch("MODAL_ACTION", {
          isShow: true,
          text: '설정을 저장하시겠습니까?',
          callback: () => {
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '저장중입니다. 잠시만 기다려 주세요.'

            this.$http.put(process.env.VUE_APP_SNAPSHOT_DOWNLOAD_SETTING, {
              customer: this.$store.state.user.customer,
              verification_max_count: max_count
            }).then(res => {
              if (res.data.success) {
                this.progressDialog['isShow'] = false
                this.testDownloadSettingDialog['isShow'] = false
              }
              else {
                this.alertDialog['isShow'] = true
                this.alertDialog['message'] = res.data.errmsg
              }
            })
          }
        })
      },
      threshold_save(item) {

        this.$store.dispatch("MODAL_ACTION", {
          isShow: true,
          text: '서비스봇에 변경된 threshold 를 적용하시겠습니까?',
          callback: () => {
            this.progressDialog['isShow'] = true
            this.progressDialog['title'] = '저장중입니다. 잠시만 기다려 주세요.'

            this.$http.post(process.env.VUE_APP_THRESHOLD_SAVE, {
              threshold_list: this.threshold_rows,
              botid: this.params['botid'],
              customer: this.params['customer'],
              requestid: this.reqSelected
            }).then(res => {
              this.progressDialog['isShow'] = false
              this.thresholdDialog['isShow'] = false
              // this.getIntentList()
              this.IntentList()

            })
          }
        })
      },
      rule_setting() {
        this.$http.get("/api/tasks/rule/", {

        }).then(res => {
          if (res.data.success) {
            const data = res.data.data
            console.log(data)
            this.ruleItems = data
            this.ruleTreeDialog['isShow'] = true
          }
        })
        // this.ruleTreeDialog['isShow'] = true
      },
      showTestDownloadSettingDialog () {
        this.$http.get(process.env.VUE_APP_SNAPSHOT_DOWNLOAD_SETTING, {
          params: {'customer': this.$store.state.user.customer}
        }).then(res => {
          if (res.data.success) {
            const data = res.data.data
            this.testDownloadSettingDialog['verificationMaxCount'] = data.verification_max_count
            this.testDownloadSettingDialog['isShow'] = true
          }
          else {
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = res.data.errmsg
          }
        })
      },
      threshold_setting () {

        this.threshold_rows= []
        this.params['botid'] = this.ModelBotSelected
        this.params['customer'] = this.$store.state.user.customer

        this.$http.get(process.env.VUE_APP_THRESHOLD_CHECK, {
          params: this.params
        }).then(res => {
          if (res.data.success) {
            const data = res.data.data
            this.threshold_rows = data
            console.log(data)
            this.thresholdDialog['isShow'] = true
          }else {
            this.alertDialog['isShow'] = true
            this.alertDialog['message'] = res.data.errmsg
            return
          }
        })
      },
      confusionMatrixCall () {
        this.$http.get(process.env.VUE_APP_CONFUSION_MATRIX_SHOW, {
          params: {
            requestid: this.reqSelected
          }
        }).then(res => {
          const data = res.data.data
          this.headers = res.data.header
          this.cnt= 0

          this.headers.forEach(d => {
            // d['html'] = true
            if (d['field'] != 'name') {
              console.log('field :', d['field'])
              // d['tdClass'] = this.tdClassFunc
              this.cnt += 1
            }
          })

          this.dataSource = data
          this.confusionDataDialog['isShow'] = true
        })
      }
    },
  }
</script>

<style>
  .red-class {
    background-color: 'green'
  }
  .green-class {
    background-color: 'green'
  }
  .level0-class {
    background-color: #EBEAF6;
  }
  .level1-class {
    background-color: #C5CAE9;
  }
  .level2-class {
    background-color: #9FA8DA;
  }
  .level3-class {
    background-color: #7986CB;
  }

  .text-class {
    background-color: #F3F3F3;
    color: 'white';
  }
  .total-class {
    background-color: #F3F3F9;
  }
</style>
